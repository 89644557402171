import React, {useState, useEffect} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { countriesData } from './countryData'; 
import authQueries from '../queries/authQueries';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Navbar from './Navbar';
import Footer from './Footer';
import InitModal from './initModal';
import { useNavigate } from 'react-router-dom';
import BootstrapLoader from '../components/Loader/BootstrapLoader';
import siteQueries from '../queries/siteQueries';
import { useScrollToTop } from '../hooks/ScrollTop';
import FeedbackModal from './FeedbackModal';
import AgeInput from '../components/Agedrop/AgeSelect';


const CustomAlert = ({ title, message }) => (
  <div className="alert alert-success" role="alert">
    <h4 className="alert-heading">{title}</h4>
    <p>{message}</p>
  </div>                   
);

const schema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  last_name: yup.string().required('Last name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().min(10, 'Phone number must be at least 10 digits').required('Phone number is required'),
});


export default function AttendeeRegistrationForm() {
  const navigate = useNavigate();

  const [isFeedbackOpen, setFeedbackOpen] = useState(false);

  const handleFeedbackOpen = () => setFeedbackOpen(true);
  const handleFeedbackClose = () => setFeedbackOpen(false);

  const handleDelegate = () => {
    navigate('/delegate-register');
  } 

    const { control, handleSubmit,reset, formState: { errors, isValid }, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          dob: '2024-11-02 00:00:00+00',
          gender: '',
          pin_code: '',
          country: "India",
          state: 'Kerala',
          address:'',
          district:'',
          termsAccepted: true
        },
        mode: 'onChange'
      });

  const [submitted, setSubmitted] = React.useState(false);
  const [feedBacksubmit, setFeedBacksubmit] = React.useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [states, setStates] = useState([]);
  const watchAllFields = watch();
  const watchCountry = watch("country");
  const [errorMessage, setErrorMessage] = useState('');
  const [code, setCode] = useState('in');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const userCreate = authQueries.useUserCreateMutation(async (response) => {
  });

  const ZohoCrm = authQueries.useZohoCrmMutation(async (response) => {
  });

  const qrCreate = siteQueries.useQrcreateMutation(async (response) => {
  });
  const onSubmit = async (data) => {
    setIsLoading(true)
    const submissionData = {
        ...data,
        username: data.email,
        role: "USER", // Adding the role field with default value "USER"
        password: "123",
        age: data.age.value,
      };

      if(data.gender===''){
        submissionData.gender='PREFER NOT TO RESPOND'
      }

      try {
        const response = await userCreate.mutateAsync(submissionData);
        
        if (response.message === 'Success') {
          const qrcode =  await qrCreate.mutateAsync({id:response?.data?.id});
          await ZohoCrm.mutateAsync({
            "firstName": data.first_name,
            "lastName": data.last_name,
            "email": data.email,
            "Phone": data.phone,
            "Zip_Code": data.pin_code,
            "Gender": data.gender,
            "State": data.state,
            "City": data.district,
            "Full_Address": data.address,
            "Type_Of_Registration":["Attendees"]
          });
          sessionStorage.setItem('qrdata', JSON.stringify(qrcode));
          setIsLoading(false)
          reset(); // Reset the form
          setSubmitted(true);
          navigate('/atsuccess');
          // You might want to show a success message or redirect the user
        } else {
          setIsLoading(false)
          setErrorMessage('Email Or Phone Already Exist.');
        }
      } catch (error) {
        setIsLoading(false)
        console.error("Error during registration:", error);
        
        if (error.response) {
          setIsLoading(false)
          setErrorMessage(error.response.data.message || 'An error occurred during registration.');
        } else if (error.request) {
          setIsLoading(false)
          setErrorMessage('No response received from the server. Please try again.');
        } else {
          setIsLoading(false)
          setErrorMessage('Email Or Phone Already Exist.');
        }
      }
    
};
  useEffect(() => {
    const allFieldsFilled = Object.values(watchAllFields).every(field => field !== '' && field !== false);
    setIsFormFilled(allFieldsFilled);
  }, [watchAllFields]);

  useEffect(() => {
    const selectedCountry = countriesData.find(country => country.name === watchCountry);
    if (selectedCountry) {
      // Sort states alphabetically before setting them
      const sortedStates = [...selectedCountry.states].sort((a, b) => a.localeCompare(b));
      setStates(sortedStates);
      setValue("state", ""); // Reset state when country changes
    } else {
      setStates([]);
    }
  }, [watchCountry, setValue]);

  // Sort the countries alphabetically
  const sortedCountries = [...countriesData].sort((a, b) => 
  a.name.localeCompare(b.name)
  );
  
  

  const handleGroup = () => {
    navigate('/attendee-group');
  }

  useScrollToTop();

  return (
    <>
    <Navbar handleOpen={handleOpen} handleClose={handleClose} />

    <div class="innerbanner registration container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <span class="logo-strips"></span> <h2>Attendee <span>Registration</span></h2>
            <p>Entry to KLF 2025 is free but registration is mandatory.</p>
          </div>
          <div class="col-md-6 d-flex justify-content-end align-items-center ">
            <a href="#" onClick={()=>{navigate('/attendee-group')}} class="btn btn-dark rounded-pill ps-4 pe-4 btn-block btn-groupregister desktoponly not-btn btn-link text-white" style={{textDecoration:'none'}}>Click here for <span>Group Registration</span>  <i class="icon-people"></i></a>
          </div>
        </div>
      </div>
    </div>

    {/* <div className="why-delegate">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5>Why be a KLF Delegate?</h5>
              <p class="mb-2">As a valued supporter of the Kerala Literature Festival (KLF), we invite you to be part of our Delegate Network. KLF has grown into Asia's largest literature festival, attracting half-a-million participants, thanks to your enthusiasm and encouragement. This achievement is further celebrated with Kozhikode being recognized as India's first UNESCO 'City of Literature'.</p>
				
            </div>
          </div>
        </div>
      </div> */}

    {isLoading ? (
      <BootstrapLoader />

       ) : (
      <div className="container">
        <div className="row">
        <div class="col-md-12 groupreg-btn-wrpr">
          <a href="#" onClick={()=>{navigate('/attendee-group')}}  class="btn btn-dark rounded-pill ps-4 pe-4 btn-block btn-groupregister phoneonly  not-btn btn-link " style={{textDecoration:'none'}}>Click here for  <span>Group Registration </span> <i class="icon-people"></i></a>
        </div>
          <div className="col-md-7 pt-5 pb-5">
            <form onSubmit={handleSubmit(onSubmit)} className="delegate-form pt-5 pb-5">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    
                    <Controller
                      name="first_name"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="First name*"
                        />
                      )}
                    />
                    {errors.first_name && <div className="invalid-feedback">{errors.first_name.message}</div>}
                  </div>
                  <div className="col-md-6">
                   
                    <Controller
                      name="last_name"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Last name*"
                        />
                      )}
                    />
                    {errors.last_name && <div className="invalid-feedback">{errors.last_name.message}</div>}
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-6">
                    
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          type="email"
                          placeholder="Email*"
                        />
                      )}
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                  </div>
                  <div className="col-md-6">
                   

                    <Controller
                      name="phone"
                      control={control}
                      className={`form-control phone-border ${errors.phone ? 'is-invalid' : ''}`}
                      render={({ field: { onChange } }) => (
                        <PhoneInput
                            className={`phoneinput ${errors.phone ? 'is-invalid' : ''}`}
                            enableSearch={true}
                            country={code}
                            // value={appStore?.appData?.mobile}
                            onChange={(value, country, e, formattedValue) => {
                              setCode(country.countryCode)
                              onChange(value)
                            }}
                        /> 
                      )}
                      rules={{ required: true }}
                    />
                   {errors.phone && <div className="invalid-feedback">{errors.phone.message}</div>}
                  </div>
                </div>

                <div className="row mt-5">

                <AgeInput control={control} errors={errors} />

                  <div className="col-md-3 gender-wrpr">
                   
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          className={`form-control ${errors.gender ? 'is-invalid' : ''}`}
                        >
                          <option value="">Select Gender</option>
                          <option value="MALE">MALE</option>
                          <option value="FEMALE">FEMALE</option>
                          <option value="OTHERS">OTHERS</option>
                          <option value="PREFER NOT TO RESPOND">PREFER NOT TO RESPOND</option>
                        </select>
                      )}
                    />
                    {errors.gender && <div className="invalid-feedback">{errors.gender.message}</div>}
                  </div>
                  <div className="col-md-6">
                    <div className='countryselect-wrpr'>
                    <Controller
                      name="country"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          className={`form-control ${errors.country ? 'is-invalid' : ''}`}
                        >
                          <option value="" selected>Select Country</option>
                          {sortedCountries.map((country, index) => (
                            <option key={index} value={country.name}>{country.name}</option>
                          ))}
                        </select>
                      )}
                    />
                    </div>
                  {errors.country && <div className="invalid-feedback">{errors.country.message}</div>}
               </div>

                  {/* <div className="col-md-4">
                   
                    <Controller
                      name="pin_code"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.pin_code ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Enter pin code"
                        />
                      )}
                    />
                    {errors.pin_code && <div className="invalid-feedback">{errors.pin_code.message}</div>}
                  </div> */}
                </div>

               
              <div className="row mt-5">

                <div className="col-md-4 state-wrpr">
                  
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                        disabled={!watchCountry}
                      >
                        <option value="">Select State</option>
                        {states.map((state, index) => (
                          <option key={index} value={state}>{state}</option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.state && <div className="invalid-feedback">{errors.state.message}</div>}
                </div>
                <div className="col-md-4 district-wrpr">
                  
                    <Controller
                      name="district"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.pin_code ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Enter District"
                        />
                      )}
                    />
              
                </div>
                  <div class="col-md-4 regbtn-wrpr text-end">
                    <button class="btn btn-primary btn-registration" type="submit">Register <i class="icon-arrow-right1"></i> </button>
                  </div>
              </div>

                  
{/* 
            <div className="row mt-5">
            <label htmlFor="address">Kindly provide your postal address to receive a token of love from the Kerala Literature Festival:</label>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <textarea
                  {...field}
                  className={`form-control ${errors.address ? 'is-invalid' : ''}`}
                  placeholder="Enter Address"
                  rows={4} // You can change the number of rows for the textarea
                />
              )}
            />
            </div> */}

                {/* <div className="row mt-5">
                  <div style={{display:'none'}} className="col-md-6">
                    <label>
                      <Controller
                        name="termsAccepted"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="checkbox"
                            checked
                            {...field}
                            className={errors.termsAccepted ? 'is-invalid' : ''}
                          />
                        )}
                      />
                      <a className="terms" href="#">Terms & Conditions</a>
                    </label>
                    {errors.termsAccepted && <div className="invalid-feedback">{errors.termsAccepted.message}</div>}
                  </div>


                </div>

                <div className="row mt-5">
                  <div style={{display:'none'}} className="col-md-6">

                   
                  </div>
                  <div className="col-md-6" style={{display:'none'}}>
                    <div className="text-end">
                    <a onClick={handleFeedbackOpen} className="btn btn-transparent btn-feedback">
                    Need help ? Please contact support
                    </a>
                    </div>
                  </div>
                </div> */}


              </div>
              <div className="row mt-5">
                  <div className="col-md-12">
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                  </div>
              </div>
            </form>

            <div class="why-delegate bottompos"><div class="container"><div class="row"><div class="col-md-12"><h5>Why be a KLF Delegate?</h5><p class="mb-2">As a valued supporter of the Kerala Literature Festival (KLF), we invite you to be part of our Delegate Network. KLF has grown into Asia's largest literature festival, attracting half-a-million participants, thanks to your enthusiasm and encouragement. This achievement is further celebrated with Kozhikode being recognized as India's first UNESCO 'City of Literature'.</p></div></div></div></div>
          </div>
         
          <div className="col-md-5 pt-5 pb-5">
          {/* <span className="delegate-register-wrpr">
          <a onClick={handleGroup} className="btn btn-dark btn-block btn-register">
          Group Registration
          </a>
          </span> */}
          <BenefitsAndRegistration  handleDelegate={handleDelegate}  />
          
          <div className="delegate-register-wrpr">
          <div className="wouldyou">Would you like to register as a delegate</div>
          <a onClick={handleDelegate} className="btn btn-dark btn-block btn-register">
            Register as Delegate
          </a>
          
        </div>
        
          </div>
        </div>
      </div>
      )}
      {submitted && (
        <CustomAlert 
          title="Success" 
          message="Your registration has been submitted successfully!"
        />
      )}

      {feedBacksubmit && (
        <CustomAlert 
          title="Success" 
          message="Your feedback has been submitted successfully!"
        />
      )}
      <InitModal isOpen={isOpen} onClose={handleClose} />
      <FeedbackModal  setFeedBacksubmit={setFeedBacksubmit}  isOpen={isFeedbackOpen} onClose={handleFeedbackClose} />
      <Footer />
    </>
  );
}


const BenefitsAndRegistration = ({handleDelegate}) => {
  const perks = [
    "Designer tote bag",
    "Designer notebook",
    "Delegate Tag",
    "Gift coupon worth 150 at Festival bookshop",
    "Gift coupon worth 150 at DC Books online shop",
    "Exclusive delegate sessions",
    "Designer Bookmark Kit",
    "Festival Calendar"
  ];
    return (
      <div className="benefits-wrpr">
      <table className="table">
        <thead>
          <tr>
            <td className="klf-drp">KLF Delegate Registration Perks</td>
            <td style={{textAlign: 'center'}}><span>Delegates</span></td>
            <td style={{textAlign: 'center'}}><span>Students</span></td>
            <td style={{textAlign: 'center'}}><span>Attendees</span></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Designer tote bag</td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-close"></i></td>
          </tr>
          <tr>
            <td>Designer notebook</td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-close"></i></td>
          </tr>
          <tr>
            <td>Delegate Tag</td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-close"></i></td>
          </tr>
          <tr>
            <td>Gift coupon worth 150 at Festival bookshop</td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-close"></i></td>
            <td className="text-center"><i className="icon-close"></i></td>
          </tr>
          <tr>
            <td>Gift coupon worth 150 at DC Books online shop</td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-close"></i></td>
          </tr>
          <tr>
            <td>Exclusive&nbsp;delegate&nbsp;sessions</td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-close"></i></td>
          </tr>
          <tr>
            <td>Designer Bookmark Kit</td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-close"></i></td>
          </tr>
          <tr>
            <td>Festival Calendar</td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-check"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
