import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const accessToken = userStore.getState().user.token
const sessionService = {

    venueList: async (user) => {
        return apiRequest({
            method:"GET",
            url: "/venue/list",
            data: user,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    // sessionList: async (Data) => {
    //     return apiRequest({
    //         method:"GET",
    //         url: "/session/list",
    //         data: Data,
    //         headers: {
    //             Authorization: "Bearer " + accessToken,
    //         }
    //     });
    // },
    sessionList: async ({ page, size }) => {
        return apiRequest({
          method: "GET",
          url: `/session/list?page=${page}&size=${size}`,  
          headers: {
            Authorization: "Bearer " + accessToken,
          }
        });
      },

    sessionCreate: async (Data) => {
        return apiRequest({
            method:"POST",
            url: "/session/create",
            data: Data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    sessionUpdate: async (Data) => {
        return apiRequest({
            method:"PUT",
            url: "/session/edit/"+Data.id,
            data: Data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    sessionView: async (id) => {
        return apiRequest({
            method:"GET",
            url: "/session/view/"+id,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },


    sessionSpeaker: async (Data) => {
        return apiRequest({
            method:"POST",
            url: "/speaker/session",
            data: Data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    venueCreate: async (Data) => {
        return apiRequest({
            method:"POST",
            url: "/venue/create",
            data: Data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },


    venueUpdate: async (Data) => {
        return apiRequest({
            method:"PUT",
            url: "/venue/edit/"+Data.id,
            data: Data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

    speakerList: async (Data) => {
        return apiRequest({
            method:"GET",
            url: "/user/speaker/list",
            data: Data,
            headers: {
                Authorization: "Bearer " + accessToken,
            }
        });
    },

   
    
}


export default sessionService;