import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { useParams ,useLocation} from 'react-router-dom';
import Footer from './Footer';
import { useScrollToTop } from '../hooks/ScrollTop';
import '../css/developer2.css';
import siteQueries from '../queries/siteQueries';
import '../css/bootstrap.min.css';
import '../css/font.css';

export default function SpeakerviewSite() {
    const [isOpen, setIsOpen] = useState(false);
    const [speaker, setSpeaker] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Loader state
    const { id } = useParams();

    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);

    const speakerData = siteQueries.useSpeakerviewsiteMutation(
        async (response) => {
            setSpeaker(response?.data);
            setIsLoading(false); // Stop loader after data is loaded
        },
    );

    useEffect(() => {
        setIsLoading(true); // Start loader when fetching begins
        speakerData.mutateAsync({ id });
    }, [id]);

    // Dynamic meta tag update effect
    useEffect(() => {
        if (speaker) {
            document.title = `${speaker.heading} | Kerala Literature Festival 2025`;
            const metaDescription = `${speaker.heading} | KLF 2025 News - Kerala Literature Festival`;
            const descriptionMeta = document.querySelector('meta[name="description"]');
            if (descriptionMeta) {
                descriptionMeta.setAttribute('content', metaDescription);
            }
            const metaKeywords = `${speaker.heading}, kerala, literature, festival, 2025`;
            const keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (keywordsMeta) {
                keywordsMeta.setAttribute('content', metaKeywords);
            }
        }
        return () => {
            document.title = 'KLF 2025 | Kerala Lit Fest | Literature Festival | Literary Festival 2025';
            const descriptionMeta = document.querySelector('meta[name="description"]');
            const keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (descriptionMeta) {
                descriptionMeta.setAttribute(
                    'content',
                    'KLF 2025 Registration - Kerala Literature Festival | Register for Kerala Lit Fest'
                );
            }
            if (keywordsMeta) {
                keywordsMeta.setAttribute(
                    'content',
                    'KLF 2025 Registration - Kerala Literature Festival | Register for Kerala Lit Fest'
                );
            }
        };
    }, [speaker]);

    const decodeHtml = (html) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
    };

    useScrollToTop();
    const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);
    useEffect(() => {
        // Retrieve current page from navigation state
        const navigationState = location.state;
        if (navigationState?.currentPage !== undefined) {
          setCurrentPage(navigationState.currentPage);
          localStorage.setItem('speakerListCurrentPage', navigationState.currentPage.toString());
        }
      }, [location]);
      localStorage.setItem('speakerListingCurrentPage', currentPage.toString());
    // Display loader while data is being loaded
    if (isLoading) {
        return (
            <div>Loading...</div>
        );
    }

    return (
        <>
            <Navbar handleOpen={handleOpen} />
            <div className="innerbanner container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <span className="logo-strips"></span>{' '}
                            <h2>
                                Speaker: <span>{speaker?.heading}</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="speakers-wrpr speaker-details-wrpr">
                <div className="container">
                    <div className="row">
                        
                        <div class="col-md-1"></div>
						
						<div class="col-md-10 speaker-pic-and-details-wrpr">
							
							<div class="card">
								<div class="speaker-pic-wrpr">
                                {speaker?.photo ? (
                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}/${speaker.photo}`}
                                    alt={speaker.heading || 'Speaker'}
                                />
                            ) : (
                                <div className="placeholder-image"></div>
                            )}
									<div class="speaker-name">{speaker?.heading}</div>
								</div>
								<div class="card-body p-4">
									<h4>{speaker?.heading}</h4>
									<p><div
                                className={`prose max-w-none mb-8 text-gray-600 leading-relaxed`}
                                dangerouslySetInnerHTML={{
                                    __html: decodeHtml(speaker.content),
                                }}
                            /></p>
									
									
								</div>
								
								
								
							</div>
						</div>
						<div class="col-md-1"></div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}
