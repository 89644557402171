import React, { useEffect, useState } from 'react';
import VenoBox from 'venobox';
import 'venobox/dist/venobox.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import siteQueries from '../queries/siteQueries';
import { useNavigate} from 'react-router-dom';

const PhotoGallery = () => {
  const  [gallery, setGallery] = useState([]);
  const navigate = useNavigate();

  const size = 6;
  const page = 0;

    useEffect(() => {
        // Initialize VenoBox
        const venobox = new VenoBox({
          selector: '.photo-venobox',
          numeration: true,
          infinigall: true,
          share: true,
          spinner: 'rotating-plane',
          spinColor: '#F4D03F',
          titleattr: 'data-title',
          titlePosition: 'top',
          titleStyle: 'bar',
          // Gallery navigation arrows
          navKeyboard: true,
          navTouch: true,
          // Callbacks
          cb_init: function(obj) {
            console.log('VenoBox initialized');
          },
          cb_post_open: function(obj) {
            console.log('Image opened');
          },
          cb_pre_close: function(obj) {
            console.log('Closing gallery');
          }
        });
      
        // Cleanup on unmount
        return () => {
          // No venobox.destroy() since it's not available
          // Placeholder for any potential cleanup tasks
        };
      }, [gallery]);
      
      useEffect(() => {
        Gallery.mutateAsync({
          size: size,
          page: page
        });
      }, []);

      const Gallery = siteQueries.useGalleryMutation(
        async (response) => {
          setGallery(response?.data?.items)
          
        },
      )
       const handleListing = () => {
        navigate('/photos');
      } 

  return (
    <div className="photogallery-wrpr">
      <div className="container">
        <div className="row">
          <div className="col-md-12 p-0 text-center">
            <h2 data-aos="fade-up" data-aos-delay="100">
              <span className="speaker-pattern"></span>
              <a href='/photos'
              class="text-decoration-none text-reset"
              >
              Photo Gallery
              </a>
              <span className="speaker-pattern"></span>
            </h2>
          </div>
          <div className="col-md-12 pt-3 pb-2 mt-3" style={{ position: 'relative' }}>
            <div className="swiper-leftarrow">
              <div className="swiper-button-prev gallery-prev"></div>
            </div>
            <Swiper
              modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
              effect='coverflow'
              grabCursor={true}
              centeredSlides={true}
              loop={true}
              speed={500}
              slidesPerView={3}
              coverflowEffect={{
                rotate: 10,
                stretch: 0,
                depth: 300,
                modifier: 3,
                slideShadows: true,
              }}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                el: '.swiper-pagination.gallery-pagination',
                clickable: true,
              }}
              navigation={{
                nextEl: '.gallery-next',
                prevEl: '.gallery-prev',
              }}
              className="swiper-container photogallery-slide"
              data-aos="fade-up"
              data-aos-delay="300"
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 30
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 40
                }
              }}
            >
              {gallery?.map((image, index) => (
                <SwiperSlide 
                key={index}
                className='swiper-slidem photo-venobox'
                data-href={`${process.env.REACT_APP_BASE_URL}/${image?.image}`}
                data-gall="gallery"
                data-title={image?.title}
                >
                  
                    <img
                    style={{ height: '200px', width: '100%', objectFit: 'cover' }}
                    src={`${process.env.REACT_APP_BASE_URL}/${image?.image}`}
                     alt={image?.alt} 
                     />
                    <div className='caption'>{image?.title}</div>
                    
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-rightarrow">
              <div className="swiper-button-next gallery-next"></div>
            </div>
            <div className="swiper-pagination-wrpr">
              <div className="swiper-pagination gallery-pagination"></div>
            </div>
          </div>
          <span class="col-md-12 text-center section-btn-wrpr pb-0">
            <button 
            className="nav-link pl-0 btn btn-sm btn-dark btn-yellow " 
            onClick={handleListing}>
              View More
              </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;