import React, {useEffect, useState} from 'react'
import Footer from './Footer';
import Navbar from './Navbar';
import InitModal from './initModal';
import { useScrollToTop } from '../hooks/ScrollTop';
import siteQueries from '../queries/siteQueries';
import Pagination from '../common/Pagination/Pagination';
import Loader from '../components/Loader/Loader';
import 'venobox/dist/venobox.min.css';
import { useNavigate,useParams } from 'react-router-dom';
import '../css/developer2.css'

export default function SpeakerListingsite() {

    const [isOpen, setIsOpen] = useState(false);
    const [speaker, setSpeaker] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [totalPage, setTotalPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
 
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    const navigate = useNavigate();
    
    const Speaker = siteQueries.useSpeakerListingMutation(
        async (response) => {
          setSpeaker(response?.data?.items);
          setTotalCount(response?.data?.totalItems);
          setTotalPage(response?.data?.totalPages);
          setIsLoading(false);
        },
      );

      useEffect(() => {
        Speaker.mutateAsync({
            page: currentPage,
            size: pageSize,
        })
      }, [currentPage, pageSize,]);
     
      const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      };

      const totalPages = totalPage;

      const decodeHtml = (html) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
      };
   

      // const handleNavigateWithState = (speaker) => {
      //   navigate(`/speakerview/${speaker.id}`);
      // };
      useEffect(() => {
        setIsLoading(true);
        
        // Retrieve the stored current page from localStorage
        const storedPage = localStorage.getItem('speakerListingCurrentPage');
        if (storedPage) {
          // Convert the stored page to a number and set it
          setCurrentPage(parseInt(storedPage, 10));
          // Optional: Clear the stored page after using it
          localStorage.removeItem('speakerListingCurrentPage');
        }
        
      
      }, [currentPage, pageSize]);
    

      const handleNavigateWithState = (speaker) => {
        navigate(`/speakerview/${speaker.id}`, { 
          state: { 
            currentPage: currentPage,  // Pass the current page as state
            pageSize: pageSize         // Optional: also pass page size if needed
          } 
        });
      };
      
      //Effect to update document title when component mounts or stateData changes
     useEffect(() => {
       
            document.title = `Speakers | Kerala Literature Festival 2025`;
        

        return () => {
            document.title = 'KLF 2025 | Kerala Lit Fest | Literature Festival | Literary Festival 2025';
        };
      
    }, []);
      

  useScrollToTop();
  return (
    <div>
        {isLoading ? (
      <Loader /> 
    ) : (
        <>
        <Navbar handleOpen={handleOpen}  />
        <div class="innerbanner container-fluid">
            <div class="container">

                <div class="row">
                    <div class="col-md-12">
                    <span class="logo-strips"></span> <h2>Speakers</h2>
                    </div>
                </div>
            </div>
        </div>
            <div>
            <div className="speakers-wrpr speakers-list">
            <div className="container">
      <div className="row">
        {speaker?.map((speaker, index) => (
          
          <div
          onClick={()=>{handleNavigateWithState(speaker)}} style={{cursor:'pointer'}} 
          class="col-md-3 pb-5 text-center"
          key={index}>
							<div class="card">
								<img src={`${process.env.REACT_APP_BASE_URL}/${speaker?.photo}`}
                alt={speaker?.name}/>
								<div class="speaker-name mb-2">{speaker?.heading}</div>
								<div class="speaker-description">
									<p>
                  <div 
                  dangerouslySetInnerHTML={{
                   __html: decodeHtml(speaker?.content)
                 }}
                 />
                    </p> 
									
								</div>
								<a href="#" data-bs-toggle="modal" data-bs-target="#about-speaker" class="btn btn-dark btn-sm btn-readmore rounded-pill mt-3 stretched-link">Read More</a>
							</div>
							
						</div>
        ))}
      </div>
    </div>
               
                <div 
                className="d-flex justify-content-center mt-4">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
                </div>
                </div>
                
            </div>
                
        <Footer handleOpen={handleOpen} />

        <InitModal isOpen={isOpen} onClose={handleClose} />
        </>
    )}
    
    </div>
  )
}
