import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Option 1: ScrollToTop Component
export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth' // for smooth scrolling
    });
  }, [pathname]);

  return null;
};

// Option 2: Custom Hook
export const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }, [pathname]);
};