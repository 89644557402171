import apiRequest from '../apis/api-request';

const groupService = {

    gqrcode: async (Data) => {
        return apiRequest({
            method:"GET",
            url: "/groupregistration/groupattendeeqr?id="+Data.id,
        });
    },
}
export default groupService;
