
import { useMutation } from "react-query";
import siteService from "../services/siteService";


const useSessionlistMutation = (onData) => {
    return useMutation(siteService.sessionList, {
      onSuccess: (data) => {
        onData(data);
      },
    });        
};


const useDelagateExistMutation = (onData) => {
  return useMutation(siteService.userExist, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useDelagatePaidstatusMutation = (onData) => {
  return useMutation(siteService.userPaid, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useQrcreateMutation = (onData) => {
  return useMutation(siteService.qrcode, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useDelegateCreateMutation = (onData) => {
  return useMutation(siteService.addDelegate, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useFeedbackMutation = (onData) => {
  return useMutation(siteService.feeback, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useVideoMutation = (onData) => {
  return useMutation(siteService.videos, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useGalleryMutation = (onData) => {
  return useMutation(siteService.gallery, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useNewsMutation = (onData) => {
  return useMutation(siteService.News, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSpeakerListingMutation = (onData) => {
  return useMutation(siteService.speakerlisting, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useTestimonialsMutation = (onData) => {
  return useMutation(siteService.Testimonials, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useAttendeeGroupMutation = (onData) => {
  return useMutation(siteService.attendeegroup, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSliderMutation = (onData) => {
  return useMutation(siteService.Sliderlist, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useNewsviewsiteMutation = (onData) => {
  return useMutation(siteService.newsviewsite, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};

const useSpeakerviewsiteMutation = (onData) => {
  return useMutation(siteService.speakerviewsite, {
    onSuccess: (data) => {
      onData(data);
    },
  });
};


const siteQueries = {

    useSessionlistMutation,
    useDelagateExistMutation,
    useDelagatePaidstatusMutation,
    useQrcreateMutation,
    useDelegateCreateMutation,
    useFeedbackMutation,
    useVideoMutation,
    useGalleryMutation,
    useNewsMutation,
    useSpeakerListingMutation,
    useTestimonialsMutation,
    useSliderMutation,
    useNewsviewsiteMutation,
    useSpeakerviewsiteMutation,
    useAttendeeGroupMutation
};

export default siteQueries;
