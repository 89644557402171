import React, {useState, useEffect} from 'react';
import Klflogo from '../images/klf-footer-logo.png';
import faebookIcon from '../images/facebook-icon.png';
import twitterIcon from '../images/x-icon.png';
import youtubeicon from '../images/youtube-icon.png';
import instagramIcon from '../images/instagram-icon.png';
import { useNavigate } from 'react-router-dom';
import FeedbackModal from './FeedbackModal';
import InitModal from './initModal';
import '../css/style.css';

const Footer = ({handleOpen}) => {
  const socialIcons = [
    { name: 'facebook', src: faebookIcon, url:'https://www.facebook.com/keralaliteraturefestival/' },
    { name: 'twitter', src: twitterIcon, url:'https://x.com/keralalitfest'  },
    { name: 'youtube', src: youtubeicon, url:'https://www.youtube.com/@DCBooksonline'  },
    { name: 'instagram', src: instagramIcon, url:'https://www.instagram.com/keralalitfest'  },
  ];
  const navigate = useNavigate();

  
  const CustomAlert = ({ title, message }) => (
    <div className="alert alert-success" role="alert">
      <h4 className="alert-heading">{title}</h4>
      <p>{message}</p>
    </div>
  );

  const [isFeedbackOpen, setFeedbackOpen] = useState(false);
  const handleFeedbackOpen = () => setFeedbackOpen(true);
  const handleFeedbackClose = () => setFeedbackOpen(false);
  const [feedBacksubmit, setFeedBacksubmit] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setIsOpen(false);

  const handleRegister = () => {
    navigate('/register');
  };

  const needHelpStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    top: '-39px',
};

const iconStyle = {
    position: 'relative',
    left: '-9px',
    top: '2px',
    color: '#f9cd26',
};

  return (
    <>
    {feedBacksubmit && (
      <CustomAlert 
        title="Success" 
        message="Your feedback has been submitted successfully!"
      />
    )}
      <footer className="container-fluid footer-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div style={needHelpStyle}>
                  <a onClick={handleFeedbackOpen} className="btn btn-transparent btn-feedback text-white">
                      <i className="icon-lifebuoy" style={iconStyle}></i>
                      Need help? Please contact support
                  </a>
              </div>
            </div>
            <div className="col-md-12">
              <div className="footer-menu-wrpr">
             
                  <a href="/">Home</a>
                  <a href="/about">About</a>
             
              </div>
            </div>
            <div className="col-md-12">
              <div className="register-social-wrpr">
                <a href="#" onClick={handleRegister} data-bs-toggle="modal" data-bs-target="#myModal" className="btn btn-dark btn-register">Register</a>
                {socialIcons.map((icon, index) => (
                  <a target='_blank' href={icon.url} key={index}>
                    <img src={icon.src} alt={icon.name} />
                  </a>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-logo-wrpr">
                <a href="#" className="">
                  <img src={Klflogo} alt="KLF Footer Logo" />
                </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-cntnt-wrpr">
                <h4>The Kerala Literature Festival</h4>
                <p>
                DC Kizhakemuri Foundation, Good Shepherd Street, Kottayam–686001, Kerala, India

                0481-2563114, 0481-2301614
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-copy">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-end">
            <span><a style={{ textDecoration:'none', color:'#fff'}} href="https://www.solminds.com/" target="_blank">Web Development</a> by Solminds.com</span>
             
              <a class="archives" target="_blank" href="https://archives.keralaliteraturefestival.com/">Archives</a>
              <span className='copy'>Copyright © {new Date().getFullYear()} keralaliteraturefestival.com</span>
            </div>
          </div>
        </div>
      </div>
      
      <FeedbackModal  setFeedBacksubmit={setFeedBacksubmit}  isOpen={isFeedbackOpen} onClose={handleFeedbackClose} />
      
    </>
  );
};

export default Footer;