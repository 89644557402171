import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';

const CCAvenue = () => {
  const [amount, setAmount] = useState('');
  const [orderId, setOrderId] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);

  // Replace these with your actual CCAvenue credentials
  const WORKING_KEY = process.env.REACT_APP_CCAVENUE_WORKING_KEY;
  const ACCESS_CODE = process.env.REACT_APP_CCAVENUE_ACCESS_CODE;
  const MERCHANT_ID = process.env.REACT_APP_CCAVENUE_MERCHANT_ID; 

  useEffect(() => {
    // Check for payment response on component mount
    const urlParams = new URLSearchParams(window.location.search);
    const encResp = urlParams.get('encResp');
    if (encResp) {
      processPaymentResponse(encResp);
    }
  }, []);

  const encrypt = (plainText) => {
    const key = CryptoJS.MD5(WORKING_KEY).toString();
    const iv = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f');
    const encrypted = CryptoJS.AES.encrypt(plainText, CryptoJS.enc.Hex.parse(key), { 
      iv: iv, 
      mode: CryptoJS.mode.CBC, 
      padding: CryptoJS.pad.Pkcs7 
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  };

  const decrypt = (encryptedText) => {
    const key = CryptoJS.MD5(WORKING_KEY).toString();
    const iv = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f');
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Hex.parse(encryptedText) },
      CryptoJS.enc.Hex.parse(key),
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  const initiatePayment = () => {
    const redirectUrl = `${window.location.origin}${window.location.pathname}`;
    const cancelUrl = `${window.location.origin}${window.location.pathname}?status=cancelled`;

    const requestData = `merchant_id=${MERCHANT_ID}&order_id=${orderId}&currency=INR&amount=${amount}&redirect_url=${redirectUrl}&cancel_url=${cancelUrl}`;
    const encryptedData = encrypt(requestData);

    const form = document.createElement('form');
    form.method = 'POST';
    form.action = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';

    const encReqField = document.createElement('input');
    encReqField.type = 'hidden';
    encReqField.name = 'encRequest';
    encReqField.value = encryptedData;

    const accessCodeField = document.createElement('input');
    accessCodeField.type = 'hidden';
    accessCodeField.name = 'access_code';
    accessCodeField.value = ACCESS_CODE;

    form.appendChild(encReqField);
    form.appendChild(accessCodeField);

    document.body.appendChild(form);
    form.submit();
  };

  const processPaymentResponse = (encResp) => {
    const decryptedResponse = decrypt(encResp);
    const responseParams = new URLSearchParams(decryptedResponse);
    const responseObject = Object.fromEntries(responseParams.entries());
    setPaymentStatus(responseObject);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">CCAvenue Payment Integration</h1>
      {!paymentStatus ? (
        <div>
          <input 
            type="text" 
            value={amount} 
            onChange={(e) => setAmount(e.target.value)} 
            placeholder="Enter amount" 
            className="border p-2 mr-2"
          />
          <input 
            type="text" 
            value={orderId} 
            onChange={(e) => setOrderId(e.target.value)} 
            placeholder="Enter order ID" 
            className="border p-2 mr-2"
          />
          <button onClick={initiatePayment} className="bg-blue-500 text-white p-2 rounded">Pay Now</button>
        </div>
      ) : (
        <div>
          <h2 className="text-xl font-semibold mb-2">Payment Response</h2>
          <pre className="bg-gray-100 p-4 rounded">
            {JSON.stringify(paymentStatus, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default CCAvenue;