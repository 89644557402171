import React, { useEffect, useState } from 'react';
import VenoBox from 'venobox';
import 'venobox/dist/venobox.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import siteQueries from '../queries/siteQueries';
import { useNavigate} from 'react-router-dom';


function SpeakerSlider() {

    const  [speaker, setSpeaker] = useState([]);
    const navigate = useNavigate();
  
    const size = 8;
    const page = 0;
                
        useEffect(() => {
          Speaker.mutateAsync({
            size: size,
            page: page
          });
        }, []);
  
        const Speaker = siteQueries.useSpeakerListingMutation(
          async (response) => {
            setSpeaker(response?.data?.items)
            
          },
        )
         const handleListing = () => {
          navigate('/speakers');
        } 
  
       

          const handleNavigateWithState = (spk) => {
            navigate(`/speakerview/${spk.id}`);
          };

  return (
      <div className="speakers-wrpr">
        <div className="container">
          <div className="row">
            <div className="col-md-12 p-0 text-center">
              <h2 data-aos="fade-up" data-aos-delay="100">
                <span className="speaker-pattern"></span>
                <a href="/speakers" className="text-decoration-none text-reset">
                  Speakers
                </a>
                <span className="speaker-pattern"></span>
              </h2>
            </div>
            <div class="col-md-1 pt-2 pb-1 mt-3">
				       <div class="swiper-button-prev speakers-prev "></div>
			      </div>
            <div className="col-md-10 pt-2 pb-1 mt-3">
              <Swiper
                spaceBetween={0}
                speed={500}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  el: '.swiper-pagination.speakers-pagination',
                  clickable: true,
                }}
                slidesPerView={5}
                navigation={{
                  nextEl: '.swiper-button-next.speakers-next',
                  prevEl: '.swiper-button-prev.speakers-prev',
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="swiper-container speakers-slide"
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                  },
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 30
                  },
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 40
                  },
                  900: {
                    slidesPerView: 4,
                    spaceBetween: 40
                  },
                  1100: {
                    slidesPerView: 5,
                    spaceBetween: 40
                  }
                }}
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="swiper-wrapper">
                  {speaker?.map((spk, index) => (
                    <SwiperSlide key={index} className="swiper-slide">
                      <div
                        onClick={() => handleNavigateWithState(spk)}
                        className="d-flex flex-column align-items-center text-center"
                        style={{cursor:'pointer'}}
                      >
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/${spk?.photo}`}
                          alt={spk?.heading}
                          className="img-fluid rounded-circle mb-2"
                          style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                        />
                        <div className="fw-bold text-dark">{spk?.heading}</div>
                      </div>
                    </SwiperSlide>
                  ))}
                </div>

               
                <div className="swiper-pagination-wrpr">
                  <div className="swiper-pagination speakers-pagination"></div>
                </div>
              </Swiper>
              <div class="swiper-pagination-wrpr ">
					       <div class="swiper-pagination speakers-pagination"></div>
				      </div>
            </div>
            <div class="col-md-1 pt-2 pb-1 mt-3">
				       <div class="swiper-button-next speakers-next"></div>
			      </div>
            <div className="col-md-12 text-center section-btn-wrpr pb-5">
              <button
                className="btn btn-sm btn-dark btn-yellow"
                onClick={handleListing}
              >
                View More
              </button>
            </div>
          </div>
        </div>
      </div>

  );

}

export default SpeakerSlider
