import React, { useState, useEffect } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import Logo from '../../images/logo/klf_white.png'
import { useNavigate } from 'react-router-dom';
import authQueries from '../../queries/authQueries';
import { userStore } from '../../store/userStore';
import Loader from '../Loader/Loader';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const store = userStore();
  const [isLoading, setIsLoading] = useState(false);

  const validateUsername = (username) => {
    if (!username) {
      setUsernameError('Username is required');
    } else if (username.length < 3) {
      setUsernameError('Username must be at least 3 characters long');
    } else  {
      setUsernameError('');
    }
  };

  const validatePassword = (password) => {
    if (!password) {
      setPasswordError('Password is required');
    } else if (password.length < 4) {
      setPasswordError('Password must be at least 8 characters long');
    } else {
      setPasswordError('');
    }
  };

  useEffect(() => {
    validateUsername(username);
    validatePassword(password);
    setIsFormValid(username && password && !usernameError && !passwordError);
  }, [username, password, usernameError, passwordError, loginError]);

  useEffect(() => {
    if (store?.user?.accessToken) {
      navigate("/userlisting");
    }
  }, []);

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (isFormValid) {
      try {
        const datavalues = {
          username,
          password
        };

        login.mutateAsync(datavalues)
        .then((response) => {
          if (response?.message) {
            setLoginError('');
            setPasswordError('');
            //navigate('/userlisting');
          } else {
           
            setLoginError('Invalid username or password');
          }
        })
      } catch (error) {
        setIsLoading(false);
        setLoginError('Invalid username or password');
        console.error('Login error:', error);
      }
    }
  };


  const login = authQueries.useLoginMutation(
    async (response) => {
      if (response?.message === "Invalid Username") {
        setIsLoading(false);
        setUsernameError(response?.message);
      } else if (response?.message === "Invalid password") {
        setIsLoading(false);
        setPasswordError(response?.message);
      } else if (response?.token) {
        setLoginError('');
        //setPasswordError('');
        setIsLoading(false);
        await store.setUser({ ...response });
        window.location.reload();
      } else {
        setIsLoading(false);
        console.error('Login error:');
        //setGeneralError('An unexpected error occurred. Please try again.');
      }
    },
    {
      onError: (error) => {
        console.error('Login error:', error);
        //setGeneralError('Failed to login. Please check your connection and try again.');
      }
    }
  );

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f7fafc', 
        padding: '0 1rem', 
      }}
    >
    {isLoading ? (
      <Loader /> 
    ) : (
      <div style={{ maxWidth: '28rem', width: '100%', margin: '0 auto' }}>
        <div style={{ textAlign: 'center' }}>
          <img
            style={{ margin: '0 auto', height: '6rem' }}
            src={Logo}
            alt="Workflow"
          />
          <h2 style={{ marginTop: '1.5rem', fontSize: '1.875rem', fontWeight: '800' }}>
            Sign In to KLF Admin
          </h2>
        </div>
        <form  autoComplete="on" style={{ marginTop: '2rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }} onSubmit={handleSubmit}>
          <div style={{ width: '100%' }}>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
              <label htmlFor="username" style={{ display: 'none' }}>
                Username
              </label>
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                style={{
                    appearance: 'none',
                    borderRadius: '0.375rem', 
                    display: 'block',
                    width: '100%',
                    padding: '0.5rem 0.75rem', 
                    border: usernameError ? '1px solid red' : '1px solid #d1d5db', 
                    placeholder: 'gray', 
                    color: '#1f2937', 
                  }}
                placeholder="Username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                  validateUsername(e.target.value);
                }}
              />
              {usernameError && (
                <p style={{ marginTop: '0.5rem', fontSize: '0.875rem', color: 'red' }}>{usernameError}</p>
              )}
            </div>
            <div style={{ marginBottom: '1rem', width: '100%' }}>
              <label htmlFor="password" style={{ display: 'none' }}>
                Password
              </label>
              <div style={{ position: 'relative' }}>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  required
                  style={{
                      appearance: 'none',
                      borderRadius: '0.375rem',
                      display: 'block',
                      width: '100%',
                      padding: '0.5rem 0.75rem 0.5rem 0.5rem', 
                      border: passwordError ? '1px solid red' : '1px solid #d1d5db',
                      placeholder: 'gray',
                      color: '#1f2937',
                    }}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    validatePassword(e.target.value);
                  }}
                />
                <button
                  type="button"
                  style={{
                        position: 'absolute',
                        top: '50%', 
                        right: '0.75rem', 
                        transform: 'translateY(-50%)', 
                        padding: '0.5rem', 
                        borderRadius: '0.375rem',
                        border: 'none', 
                        background: 'transparent', 
                        cursor: 'pointer', 
                      }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff style={{ height: '1.25rem', width: '1.25rem', color: '#9ca3af' }} />
                  ) : (
                    <Eye style={{ height: '1.25rem', width: '1.25rem', color: '#9ca3af' }} />
                  )}
                </button>
              </div>
              {passwordError && (
                <p style={{ marginTop: '0.5rem', fontSize: '0.875rem', color: 'red' }}>{passwordError}</p>
              )}
              {loginError && (
                <p style={{ marginTop: '0.5rem', fontSize: '0.875rem', color: 'red' }}>{loginError}</p>
              )}
            </div>
          </div>

          <div>
            <button
              type="submit"
              style={{
                  width: '450px',
                  padding: '0.5rem',
                  border: '1px solid transparent',
                  fontSize: '0.875rem',
                  fontWeight: '500',
                  borderRadius: '0.375rem',
                  color: 'white',
                  backgroundColor: isFormValid ? '#4f46e5' : '#818cf8', 
                  cursor: isFormValid ? 'pointer' : 'not-allowed',
                  opacity: isFormValid ? '1' : '0.5',
                }}
              disabled={!isFormValid}
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    )}
    </div>
  );

};

export default Login;


