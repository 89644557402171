import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import siteQueries from '../queries/siteQueries';
import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';
import { useNavigate} from 'react-router-dom';
import RouteConstants from '../constant/Routeconstant';

const News = () => {
const [news, setNews] = useState()
const navigate = useNavigate();

const size = 6;
const page = 0;
  
    const newslist = siteQueries.useNewsMutation(
        async (response) => {
          setNews(response?.data?.items);
        },
        {
          onError: (error) => {
          }
        }
      );

      useEffect(() => {
        newslist.mutateAsync({
          size: size,
          page: page
        })
      }, []);

      const handleListing = () => {
        navigate(RouteConstants.NEWSLIST);
      } 

      const decodeHtml = (html) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
      }; 

      // const handleView = (item) => {
      //   navigate('/newsview', { state: { item } })
      // } 

      const handleView = (item) => {
        navigate(`/newsview/${item.id}`);
      };

    return(
    <div className="news-wrpr">
      <div className="container">
        <div className="row">
          <div className="col-md-12 p-0 text-center">
            <h2 data-aos="fade-up" data-aos-delay="100">
              <span className="speaker-pattern"></span>
              <a 
              class="text-decoration-none text-reset"
              href='/news'> 
              KLF News
              </a>
              <span className="speaker-pattern"></span>
            </h2>
          </div>
          <div className="col-md-12 pt-3 pb-2 mt-0" style={{position: 'relative'}}>
            <div className="swiper-leftarrow">
              <div className="swiper-button-prev news-prev"></div>
            </div>
            <Swiper
               spaceBetween={100}
               speed={500}
               loop={true}
               slidesPerView={3}
               autoplay={{
               delay: 2500,
               disableOnInteraction: false,
               }}
               pagination={{
                el: '.swiper-pagination.news-pagination',
                //dynamicBullets: true,
                clickable: true,
               }}
              
               navigation={{
                nextEl: ".swiper-button-next.news-next",
                prevEl: ".swiper-button-prev.news-prev",
               }}
               modules={[Autoplay, Pagination, Navigation]}
               className="swiper-container news-slide"
               breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 3,
                  spaceBetween: 30
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 3,
                  spaceBetween: 40
                }
               }}
            >
              {news && news.length > 0 ? (
                news.map((item, index) => (
                  <SwiperSlide key={index} className="swiper-slide text-center"
                    style={{ textDecoration: 'none', color: 'black' }}
                    >
                      
                    <div className="card news-card cursor-pointer"
                    onClick={() => handleView(item)}
                    style={{ cursor: 'pointer'}}>
                    <img 
                    style={{height: '200px', objectFit: 'cover'}}
                    src={`${process.env.REACT_APP_BASE_URL}/${item.image}`} />
                     
                     
                       <h2 className="text-truncate">{item.shortdescription}</h2>
                      <p
                      style={{
                        display: '-webkit-box',
                        WebkitLineClamp: 6, 
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>
                        <span
                        dangerouslySetInnerHTML={{
                        __html: decodeHtml(item.maindescription)
                      }}
                      /></p>
                      
                    </div>
                  
                   
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide className="swiper-slide text-center">
                  <p>Loading news...</p>
                </SwiperSlide>
              )}
              <div className="swiper-pagination-wrpr">
                <div className="swiper-pagination news-pagination"></div>
              </div>
            </Swiper>
            <div className="swiper-rightarrow">
              <div className="swiper-button-next news-next"></div>
            </div>
              <div class="col-md-12 text-center section-btn-wrpr">
            <button 
            className="nav-link pl-0 btn btn-sm btn-dark btn-yellow " 
            onClick={handleListing}>
              View More
              </button>
          </div>
          </div>
        </div>
      </div>
    </div>
    )
};
  export default News
