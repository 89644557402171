import React ,{useState, useEffect} from 'react';
import { Users, ShoppingCart, CreditCard } from 'lucide-react';
import userQueries from '../../queries/userQueries';
import { useNavigate } from 'react-router-dom';

const DashboardTiles = () => {

    const [count, setCount]= useState({});
    const navigate = useNavigate();

    useEffect(() => {
        userCount.mutateAsync();
      }, []);

    const userCount = userQueries.useUserCountMutation(
        async (response) => {
            setCount(response);
        }
      );
    
  // Sample data - replace with your actual data
  const stats = [
    {
      title: "Total Attendees",
      count: count?.attendeesTotal,
      icon: Users,
      trend: "+12.5%",
      trendUp: true,
      bgColor: "bg-blue-500",
      hoverColor: "hover:bg-blue-600",
      link:"/userlisting"
    },
    {
      title: "Total Delegates",
      count: count?.delegatesTotal,
      icon: Users,
      trend: "+23.1%",
      trendUp: true,
      bgColor: "bg-green-500",
      hoverColor: "hover:bg-green-600",
      link:"/delegatelisting"
    },
    {
      title: "Total Students",
      count: count?.studentTotal,
      icon: Users,
      trend: "-4.3%",
      trendUp: false,
      bgColor: "bg-purple-500",
      hoverColor: "hover:bg-purple-600",
      link:"/student"
    }
  ];

  const handleClick = (link) =>{
    navigate(link)
  }

  return (
    <div className="p-6 w-full">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {stats.map((stat, index) => (
          <div
          style={{cursor:'pointer'}}
          onClick={()=>{handleClick(stat?.link)}}
            key={index}
            className={`${stat.bgColor} ${stat.hoverColor} rounded-lg p-8 text-white transition-all duration-300 transform hover:scale-105 hover:shadow-xl`}
          >
            <div className="flex items-center justify-between">
              <div className="flex flex-col">
                <span className="text-lg font-semibold opacity-90">
                  {stat.title}
                </span>
                <span className="text-3xl font-bold mt-2">
                  {stat.count}
                </span>
              </div>
              <div className="bg-white/20 p-3 rounded-lg">
                <stat.icon className="w-8 h-8 text-white" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DashboardTiles;