import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const InitModal = ({ isOpen, onClose }) => {
    const navigate = useNavigate();

    const handleDelegate = () => {
        navigate('/delegate-register');
    }

    const handleAttendee = () => {
        navigate('/attendee-register');
    }

    const handleStudent = () => {
      navigate('/student-register');
  }

  return (
    <Modal show={isOpen} onHide={onClose} size="fullscreen" id="registrationType" className="registration-type-popup">
      <Modal.Header closeButton>
        <Modal.Title><span></span> Registration</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="popup-columns">

        <Col md={12}>
          <div className="dele-perks">
							<strong>KLF delegate registration perks</strong><br/>
							<ul>
								<li>Designer tote bag</li>
								<li>Designer notebook</li>
								<li>Delegate Tag</li>
								<li>Designer Bookmark Kit</li>
								<li>Gift coupon worth 150 at Festival bookshop</li>
								<li>Gift coupon worth 150 at DC Books online shop</li>
								<li>Exclusive delegate sessions</li>
                <li>Festival Calendar</li>
								
								
							</ul>
						</div>
        </Col>
          <Col md={4}>
          <div className="popup-boxset">
            <h3><span></span> Delegate Registration</h3>
            <div className="why-delegate">
            <h4>Why be a KLF Delegate?</h4>
							<p>By being a KLF Delegate you will have access to our finest speakers and exclusively curated sessions, along with priority book signing access, gift coupons and other goodies.</p>
							<p>You can be a delegate by paying <span class="rupees orange">Rs. 1399</span> <br/>Early Bird Offer <span class="rupees yellow ">@ Rs 1199</span> valid till 20th, Dec 2024.</p>
            </div>
            </div>
            <div className="delegate-btn-cntnr">
              <Button onClick={handleDelegate}  variant="outline-primary" size="lg" className="btn-reg">
                Delegate Registration
              </Button>
            </div>
          </Col>
          <div className="or-cntnr">
            OR
          </div>
          <Col md={4}>
            <div className="popup-boxset">
							<h3><span></span> Student Registration</h3>
							<div className="why-delegate">
								<p>Be a student delegate <br/>
								  @ Rs. 699 only .</p>
							</div>
						</div>
            <div className="delegate-btn-cntnr">
							
							<a onClick={handleStudent} className="btn btn-lg btn-transparent btn-reg">Student Registration</a>
							
						</div>
          </Col>
         
          <Col md={4}>
          <div className="popup-boxset">
            <h3><span></span> Attendee Registration</h3>
            <div className="why-delegate">
              <p>Entry to KLF 2025 is free <br/>
              but registration is mandatory.</p>
            </div>
            </div>
            <div className="delegate-btn-cntnr">
              <a onClick={handleAttendee} size="lg" className="btn btn-lg btn-transparent btn-reg">
                Attendee Registration
              </a>
            </div>
          </Col>

          <div className="or-cntnr another-or">
            OR
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default InitModal;