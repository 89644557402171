import React, { useState,useRef,useEffect } from "react";
import userQueries from '../../queries/userQueries';
import { userStore } from "../../store/userStore";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";



const EditAdmin = () => {
  
    const [gender, setGender] = useState("");
    const [usernameExist, setUsernameExist] = useState('')
    const [usernameerror,setUsernameerror] = useState(false);
    const [susmsg,setSusmsg] = useState(false);
    const [gendererror,setGendererror] = useState(false);
    const imageRef = useRef(null);
    const navigate = useNavigate();

    const { id } = useParams();
     const Speakerdata = userQueries.useSpeakerupdateMutation(
        (response) => {
            if(response?.data){
                let datas = {
                    id: id,
                    first_name: response?.data.first_name,
                    last_name: response?.data.last_name,
                    age: response?.data.age,
                    email: response?.data.email,
                    gender: response?.data.gender,
                    phone: response?.data.phone,
                    pin_code: response?.data.pin_code,
                };
                
                // Reset the form with the fetched data
                reset({ ...datas });
                
                // Update the gender state separately
                setGender(response?.data.gender); 
            }
        }
    );
    useEffect(() => {
        Speakerdata.mutateAsync({ id });
    }, [id]);


    const { register ,reset, control, handleSubmit, formState:{ errors } } = useForm({
        
    });
  
    const editSpeaker = userQueries.useSpeakerupdateMutation(
        (response) => {
            if(response?.message === "Success"){
         alert("success");
         navigate('/adminlisting');
            }
            
        }   
    );
        
    
    const onSubmit = (data) => {
        const datavalues = {
                    id:id,
                    first_name:data.first_name,
                    last_name:data.last_name,
                    age:data.age,
                    email:data.email,
                    gender:data.gender,
                    phone:data.phone,
                    pin_code:data.pin_code,
        };
        editSpeaker.mutateAsync(datavalues);
    }

    return (
        <>
        <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
            Update Admin
        </h4>
        <form  onSubmit={handleSubmit(onSubmit)} className="max-w-lg mx-auto grid grid-cols-2 gap-4">

         

      
    
        {/* Email */}
        <div className="mb-5 col-span-1">
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
            <input type="email" id="email" {...register("email")} name="email" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" />
            {usernameerror && <div className="text-red-600 text-s">Please Enter email</div>}
            {usernameExist && <div className="text-red-600 text-s">Email already exists</div>}
        </div>
    
        {/* First Name */}
        <div className="mb-5 col-span-1">
            <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
            <input type="text" id="first_name" {...register("first_name")} name="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" />
        </div>
    
        {/* Last Name */}
        <div className="mb-5 col-span-1">
            <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
            <input type="text" id="last_name" {...register("last_name")} name="last_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" />
        </div>
    
        {/* Age */}
        <div className="mb-5 col-span-1">
            <label htmlFor="age" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Age</label>
            <input type="number" id="age" {...register("age")} name="age" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" />
        </div>
    
        {/* Phone */}
        <div className="mb-5 col-span-1">
            <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone</label>
            <input type="tel" id="phone" {...register("phone")} name="phone" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" />
        </div>
    
        {/* Gender */}
        <div className="mb-5 col-span-1">
            <label htmlFor="gender" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Gender</label>
            <select
                id="gender"
                {...register("gender")}
                name="gender"
                value={gender} 
                onChange={(e) => setGender(e.target.value)} 
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
            >
                <option value="">Select Gender</option> 
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
                <option value="OTHER">Other</option>
            </select>
            {gendererror && <div className="text-red-600 text-s">Please Select gender</div>}
        </div>
        {/* Pin Code */}
        <div className="mb-5 col-span-1">
            <label htmlFor="pin_code" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Pin Code</label>
            <input type="text" id="pin_code" {...register("pin_code")} name="pin_code" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" />
        </div>

        
        
    
        <button type="submit" className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
           Update Admin
        </button>
    
        {susmsg && <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap">
            Admin updated successfully
        </div>}
    </form>
    
    
        </>
    );
};

export default EditAdmin;
