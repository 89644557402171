import { useEffect, useCallback, useState } from 'react';
import authQueries from '../../queries/authQueries';

const useRazorpay = ({ 
  key, 
  amount, 
  currency = 'INR', 
  name, 
  description, 
  prefill, 
  theme = { color: '#3399cc' }, 
  onSuccess, 
  onError 
}) => {
  const [orderId, setOrderId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const loadScript = useCallback(() => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  }, []);

  useEffect(() => {
    loadScript();
  }, [loadScript]);


  const createOrderApi = authQueries.usePaymentOrderMutation(async (response) => {
    
  });
  //const verifyPaymentApi = authQueries.useVerifyPaymentMutation();

  const createOrder = useCallback(async () => {
    setIsLoading(true);
    try {
      const orderData = {
        amount: amount,
        currency: currency
      };
      const data = await createOrderApi.mutateAsync(orderData);
      setOrderId(data.order.id);
      return data.order.id;
    } catch (error) {
      console.error('Error creating order:', error);
      onError(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [amount, currency, createOrderApi, onError]);

  // const verifyPayment = useCallback(async (paymentData) => {
  //   try {
  //     const verificationData = await verifyPaymentApi.mutateAsync(paymentData);
  //     if (verificationData.verified) {
  //       onSuccess(paymentData);
  //     } else {
  //       onError(new Error('Payment verification failed'));
  //     }
  //   } catch (error) {
  //     console.error('Error verifying payment:', error);
  //     onError(error);
  //   }
  // }, [verifyPaymentApi, onSuccess, onError]);

  const openPayModal = useCallback(async () => {
    if (isLoading) return;

    const res = await loadScript();
    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    setIsLoading(true);
    const generatedOrderId = orderId || await createOrder();
    setIsLoading(false);

    if (!generatedOrderId) {
      alert('Could not generate order ID');
      return;
    }

    const options = {
      key,
      amount: amount * 100, // Razorpay expects amount in paise
      currency,
      name,
      description,
      order_id: generatedOrderId,
      prefill,
      theme,
      handler: function(response) {
        onSuccess(response);
       // verifyPayment(response);
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on('payment.failed', function(response) {
      onError(response.error);
    });
    paymentObject.open();
  }, [key, amount, currency, name, description, prefill, theme, onSuccess, onError, loadScript, createOrder, orderId, isLoading]);

  return { openPayModal, createOrder, isLoading };
};

export default useRazorpay;