
import { useMutation } from "react-query";
import userService from "../services/userService";

const useUserlistMutation = (onUserlistSuccess) => {
    return useMutation(userService.userlist, {
      onSuccess: (data) => {
        onUserlistSuccess(data);
      },
    });
};

const useSpeakerlistMutation = (onSpeakerlistSuccess) => {
  return useMutation(userService.speakerlist, {
    onSuccess: (data) => {
      onSpeakerlistSuccess(data);
    },
  });
};


const useCreateuserMutation = (onCreateuserSuccess) => {
  return useMutation(userService.createuser, {
    onSuccess: (data) => {
      onCreateuserSuccess(data);
    },
  });
};

const useSpeakerimageMutation = (onSpeakerimageSuccess) => {
  return useMutation(userService.speakerimageupload, {
    onSuccess: (data) => {
      onSpeakerimageSuccess(data);
    },
  });
};

const useSliderlistingMutation = (onSliderlistingSuccess) => {
  return useMutation(userService.sliderlisting, {
    onSuccess: (data) => {
      onSliderlistingSuccess(data);
    },
  });
};

const useSlidercreateMutation = (onSlidercreateSuccess) => {
  return useMutation(userService.slidercreate, {
    onSuccess: (data) => {
      onSlidercreateSuccess(data);
    },
  });
};

const useSpeakerupdateMutation = (onSpeakerupdateSuccess) => {
  return useMutation(userService.speakerupdate, {
    onSuccess: (data) => {
      onSpeakerupdateSuccess(data);
    },
  });
};

const useAdminListingMutation = (onAdminListing) => {
  return useMutation(userService.adminlist, {
    onSuccess: (data) => {
      onAdminListing(data)
    }
  })
}

const useDelegateListing = (onDelegate) => {
  return useMutation (userService.delegatelist, {
    onSuccess: (data) => {
      onDelegate(data)
    }
  })
}

const useUpdateuserMutation = (onCreateuserSuccess) => {
  return useMutation(userService.updateuser, {
    onSuccess: (data) => {
      onCreateuserSuccess(data);
    },
  });
};

const useStudentlistMutation = (ondataSuccess) => {
  return useMutation(userService.studentlist, {
    onSuccess: (data) => {
      ondataSuccess(data);
    },
  });
};


const useUserCountMutation = (ondataSuccess) => {
  return useMutation(userService.userCount, {
    onSuccess: (data) => {
      ondataSuccess(data);
    },
  });
};

const useUserViewtMutation = (onViewSuccess) => {
  return useMutation(userService.userview, {
    onSuccess: (data) => {
      onViewSuccess(data);
    },
  });
};

const useUserexportMutation = (onUserexportSuccess) => {
  return useMutation(userService.userexport, {
    onSuccess: (data) => {
      onUserexportSuccess(data);
    },
  });
};

const useDelegatesexportMutation = (onDelegatesexportSuccess) => {
  return useMutation(userService.delegatesexport, {
    onSuccess: (data) => {
      onDelegatesexportSuccess(data);
    },
  });
};

const useStudentsexportMutation = (onStudentsexportSuccess) => {
  return useMutation(userService.studentsexport, {
    onSuccess: (data) => {
      onStudentsexportSuccess(data);
    },
  });
};

const useUserzohoexportMutation = (onUserzohoexportSuccess) => {
  return useMutation(userService.userexportzoho, {
    onSuccess: (data) => {
      onUserzohoexportSuccess(data);
    },
  });
};

const useDelegateszohoexportMutation = (onDelegateszohoexportSuccess) => {
  return useMutation(userService.delegatesexportzoho, {
    onSuccess: (data) => {
      onDelegateszohoexportSuccess(data);
    },
  });
};

const useStudentszohoexportMutation = (onStudentszohoexportSuccess) => {
  return useMutation(userService.studentsexportzoho, {
    onSuccess: (data) => {
      onStudentszohoexportSuccess(data);
    },
  });
};

const useNewslistMutation = (onNewslistSuccess) => {
  return useMutation(userService.newslist, {
    onSuccess: (data) => {
      onNewslistSuccess(data);
    },
  });
};

const useCreatenewsMutation = (onCreatenewsSuccess) => {
  return useMutation(userService.createnews, {
    onSuccess: (data) => {
      onCreatenewsSuccess(data);
    },
  });
};

const useUpdatenewsMutation = (onUpdatenewsSuccess) => {
  return useMutation(userService.newsupdate, {
    onSuccess: (data) => {
      onUpdatenewsSuccess(data);
    },
  });
};

const useVideoslistMutation = (onVideoslistSuccess) => {
  return useMutation(userService.videoslist, {
    onSuccess: (data) => {
      onVideoslistSuccess(data);
    },
  });
};

const useCreatevideosMutation = (onCreatevideosSuccess) => {
  return useMutation(userService.createvideos, {
    onSuccess: (data) => {
      onCreatevideosSuccess(data);
    },
  });
};

const useUpdatevideosMutation = (onUpdatevideosSuccess) => {
  return useMutation(userService.videosupdate, {
    onSuccess: (data) => {
      onUpdatevideosSuccess(data);
    },
  });
};

const usePhotogallerylistMutation = (onPhotogallerylistSuccess) => {
  return useMutation(userService.photogallerylist, {
    onSuccess: (data) => {
      onPhotogallerylistSuccess(data);
    },
  });
};

const useCreatephotogalleryMutation = (onCreatephotogallerySuccess) => {
  return useMutation(userService.createphotogallery, {
    onSuccess: (data) => {
      onCreatephotogallerySuccess(data);
    },
  });
};

const useUpdatephotogalleryMutation = (onUpdatephotogallerySuccess) => {
  return useMutation(userService.photogalleryupdate, {
    onSuccess: (data) => {
      onUpdatephotogallerySuccess(data);
    },
  });
};

const useDeletephotogalleryMutation = (onDeletephotogallerySuccess) => {
  return useMutation(userService.photogalleryDelete, {
    onSuccess: (data) => {
      onDeletephotogallerySuccess(data);
    },
  });
};

const useDeletenewsMutation = (onDeletenewsSuccess) => {
  return useMutation(userService.newsDelete, {
    onSuccess: (data) => {
      onDeletenewsSuccess(data);
    },
  });
};
const useDeletevideosMutation = (onDeletevideosSuccess) => {
  return useMutation(userService.videosDelete, {
    onSuccess: (data) => {
      onDeletevideosSuccess(data);
    },
  });
};

const usePublishspeakerMutation = (onPublishspeakerSuccess) => {
  return useMutation(userService.publishspeaker, {
    onSuccess: (data) => {
      onPublishspeakerSuccess(data);
    },
  });
};

const usePublishnewsMutation = (onPublishnewsSuccess) => {
  return useMutation(userService.publishnews, {
    onSuccess: (data) => {
      onPublishnewsSuccess(data);
    },
  });
};
const usePublishphotogalleryMutation = (onPublishphotogallerySuccess) => {
  return useMutation(userService.publishphotogallery, {
    onSuccess: (data) => {
      onPublishphotogallerySuccess(data);
    },
  });
};

const usePublishvideosMutation = (onPublishvideosSuccess) => {
  return useMutation(userService.publishvideo, {
    onSuccess: (data) => {
      onPublishvideosSuccess(data);
    },
  });
};

const useListspeakersiteMutation = (onListspeakersiteSuccess) => {
  return useMutation(userService.listspeakersite, {
    onSuccess: (data) => {
      onListspeakersiteSuccess(data);
    },
  });
};



const useListnewssiteMutation = (onListnewssiteSuccess) => {
  return useMutation(userService.listnewssite, {
    onSuccess: (data) => {
      onListnewssiteSuccess(data);
    },
  });
};



const useListvideossiteMutation = (onListvideossiteSuccess) => {
  return useMutation(userService.listvideossite, {
    onSuccess: (data) => {
      onListvideossiteSuccess(data);
    },
  });
};



const useListphotogallerysiteMutation = (onListphotogallerysiteSuccess) => {
  return useMutation(userService.listphotogallerysite, {
    onSuccess: (data) => {
      onListphotogallerysiteSuccess(data);
    },
  });
};

const useDeletespeakerMutation = (onDeletespeakerSuccess) => {
  return useMutation(userService.speakersDelete, {
    onSuccess: (data) => {
      onDeletespeakerSuccess(data);
    },
  });
};

const useCreatespeakerMutation = (onCreatespeakerSuccess) => {
  return useMutation(userService.createspeaker, {
    onSuccess: (data) => {
      onCreatespeakerSuccess(data);
    },
  });
};

const useUpdatespeakerMutation = (onUpdatespeakerSuccess) => {
  return useMutation(userService.updatespeaker, {
    onSuccess: (data) => {
      onUpdatespeakerSuccess(data);
    },
  });
};




const useCreatetestimonialsMutation = (onCreatetestimonialsSuccess) => {
  return useMutation(userService.createtestimonials, {
    onSuccess: (data) => {
      onCreatetestimonialsSuccess(data);
    },
  });
};

const useUpdatetestimonialsMutation = (onUpdatetestimonialsSuccess) => {
  return useMutation(userService.testimonialsupdate, {
    onSuccess: (data) => {
      onUpdatetestimonialsSuccess(data);
    },
  });
};

const useListtestimonialsMutation = (onListtestimonialsSuccess) => {
  return useMutation(userService.testimonialslist, {
    onSuccess: (data) => {
      onListtestimonialsSuccess(data);
    },
  });
};

const useDeletetestimonialsMutation = (onDeletetestimonialsSuccess) => {
  return useMutation(userService.testimonialsDelete, {
    onSuccess: (data) => {
      onDeletetestimonialsSuccess(data);
    },
  });
};

const usePublishtestimonialsMutation = (onPublishtestimonialsSuccess) => {
  return useMutation(userService.publishtestimonials, {
    onSuccess: (data) => {
      onPublishtestimonialsSuccess(data);
    },
  });
};

const usePublishsliderMutation = (onPublishsliderSuccess) => {
  return useMutation(userService.publishslider, {
    onSuccess: (data) => {
      onPublishsliderSuccess(data);
    },
  });
};

const useDeletesliderMutation = (onDeletesliderSuccess) => {
  return useMutation(userService.deleteslider, {
    onSuccess: (data) => {
      onDeletesliderSuccess(data);
    },
  });
};

const useUpdatesliderMutation = (onUpdatesliderSuccess) => {
  return useMutation(userService.updateslider, {
   
    onSuccess: (data) => {
      onUpdatesliderSuccess(data);
    },
  });
};

const userQueries = {
  useUserlistMutation,
  useSpeakerlistMutation,
  useCreateuserMutation,
  useSpeakerimageMutation,
  useSliderlistingMutation,
  useSlidercreateMutation,
  useSpeakerupdateMutation,
  useAdminListingMutation,
  useDelegateListing,
  useUpdateuserMutation,
  useStudentlistMutation,
  useUserCountMutation,
  useUserViewtMutation,
  useUserexportMutation,
  useStudentsexportMutation,
  useDelegatesexportMutation,
  useUserCountMutation,
  useUserzohoexportMutation,
  useStudentszohoexportMutation,
  useDelegateszohoexportMutation,
  useNewslistMutation,
  useCreatenewsMutation,
  useUpdatenewsMutation,
  useCreatevideosMutation,
  useUpdatevideosMutation,
  useVideoslistMutation,
  usePhotogallerylistMutation,
  useCreatephotogalleryMutation,
  useUpdatephotogalleryMutation,
  useDeletephotogalleryMutation,
  useDeletenewsMutation,
  useDeletevideosMutation,
  usePublishspeakerMutation,
  usePublishnewsMutation,
  usePublishvideosMutation,
  usePublishphotogalleryMutation,
  useListspeakersiteMutation,
  useListnewssiteMutation,
  useListvideossiteMutation,
  useListphotogallerysiteMutation,
  useDeletespeakerMutation,
  useCreatespeakerMutation,
  useUpdatespeakerMutation,
  useCreatetestimonialsMutation,
  useUpdatetestimonialsMutation,
  useListtestimonialsMutation,
  useDeletetestimonialsMutation,
  usePublishtestimonialsMutation,
  usePublishsliderMutation,
  useDeletesliderMutation,
  useUpdatesliderMutation
  
  
  

};

export default userQueries;
