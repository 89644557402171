import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const PaymentFailed = () => {
  return (
    <>
    <Navbar />
    <div className="innerbanner paymentfailed container-fluid">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="card failed-card">
              <i className="icon-minus1"></i>
              <div className="thankyou-hding">Failed!</div>
              <div className="payment-done">Unfortunately, payment was rejected.</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
    </>
  );
};

export default PaymentFailed;
