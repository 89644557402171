import React, {useState} from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
import InitModal from './initModal';

export default function Terms() {

    const [isOpen, setIsOpen] = useState(false);
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);

  return (
    <>
       <Navbar handleOpen={handleOpen}  />
        <div class="innerbanner container-fluid">
            <div class="container">

                <div class="row">
                    <div class="col-md-12">
                        <span class="logo-strips"></span> <h2>Terms &amp; <span>Conditions</span></h2>
                    </div>
                </div>
            </div>
        </div>

        <TermsAndConditions />

        <Footer handleOpen={handleOpen} />

        <InitModal isOpen={isOpen} onClose={handleClose} />

    </>
  )
}


const TermsAndConditions = () => {  
    return (
        <div class="container mt-5 mb-5">
        <div class="row">
            <div class="col-md-12">
                
                <p>Entry to KLF 2025 is free but registration is mandatory.</p>
                <p>KLF has the right to amend, cancel, postpone its sessions, speakers of any component that comprise the programming without prior notice.</p>
                <p>Attendee registration is free of cost and does not provide for any perks stipulated under the offerings of Delegate Registration.</p>
                <p>The organizing committee reserves the right to make any changes in the event/ speakers/ sessions/ timings in unforeseeable circumstances.</p>
                <p><strong>Delegate registration</strong> and its offerings can only be availed at the festival venue.</p>
                <p>The Coupons for books worth …. from the KLF bookstore are only redeemable from the venue and shall not be allowed for use at any other DC Books Outlet.</p>
                <p>The Coupons for Online store can be availed only from DC Books online store and shall expire on …. ….. mid night of the year 2025. </p>
                <p>The organizing committee reserves the right to make any changes in the event/ speakers/ sessions/ timings in unforeseeable circumstances.</p>
                <p>Seating and access for the delegate only session is on a first come first serve basis and KLF does not guarantee seating or access to all delegates.</p>
                <p>Sessions for the Delegate only events are subject to change.</p>
                <p>KLF reserves the right to make amends, modifications or cancellations without prior notice.</p>
                <p>Registration as a delegate is non-refundable and all accessories and benefits that are committed under the delegate registration must be used at the KLF 2025 venue during the event dates.</p>
                <p>Delegate registration is for limited numbers and is non transferrable.</p>
                <p>In the event wherein KLF 2025 is cancelled due to unforeseen circumstances, the amount paid towards registration shall be returned, any taxes levied are subject to government discretion and KLF nor its organisers shall be liable for any such amount as dictated to be deducted or charged as laid out by the law. </p>
                <p>Any disputes, claims, or controversies arising out of or in connection with this agreement shall be governed and construed in accordance with the laws of India, and shall be subject to the exclusive jurisdiction of the courts in Kottayam, Kerala.</p>
                
                
            </div>
            
            
        </div>
    </div>
    );
  };
  
