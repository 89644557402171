import { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useNavigate } from 'react-router-dom';
import RouteConstants from '../constant/Routeconstant';
import siteQueries from '../queries/siteQueries';

const colors = ['yellow', 'red', 'green', 'orange'];

const getColorByIndex = (index) => {
  return colors[index % colors.length];
};

const decodeHtml = (html) => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = html;
  return textArea.value;
};

const TestimonialCard = ({ color = 'yellow', content, name, photo }) => (
  <div className={`card testimonial-card ${color}`}>
    <div className="testim-user">
      <img 
        src={`${process.env.REACT_APP_BASE_URL}/${photo}`} 
        alt={name} 
      />
    </div>
    <div className="testim-cntnt">
      <div className="testimonial-para-wrpr">
        <div 
          dangerouslySetInnerHTML={{ 
            __html: content 
          }} 
        />
      </div>
      <div className="testim-user-name">{name}</div>
      <div className="testim-user-desig"></div>
    </div>
  </div>
);

const Testimonials = () => {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const navigate = useNavigate();
  const swiperRef = useRef(null);
  const containerRef = useRef(null);

  const page = 0;
  
  const testimonialslist = siteQueries.useTestimonialsMutation(
    async (response) => {
      if (response?.data?.items) {
        const testimonialsWithColors = response.data.items.map((item, index) => ({
          ...item,
          content: decodeHtml(item.content), // Decode the content when setting the data
          color: getColorByIndex(index)
        }));
        setTestimonialsData(testimonialsWithColors);
      }
    },
    {
      onError: (error) => {
        console.error('Error fetching testimonials:', error);
      }
    }
  );

  useEffect(() => {
    testimonialslist.mutateAsync({
      page: page
    });
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    
    if (!container || !swiperRef.current?.swiper) return;

    const stopAutoplay = () => {
      const swiper = swiperRef.current.swiper;
      const swiperTranslate = swiper.getTranslate();
      swiper.setTranslate(swiperTranslate);
      swiper.autoplay.stop();
    };

    const startAutoplay = () => {
      const swiper = swiperRef.current.swiper;
      swiper.slideTo(swiper.activeIndex, 300, false);
      swiper.autoplay.start();
    };

    container.addEventListener('mouseenter', stopAutoplay);
    container.addEventListener('mouseleave', startAutoplay);

    return () => {
      container.removeEventListener('mouseenter', stopAutoplay);
      container.removeEventListener('mouseleave', startAutoplay);
    };
  }, [testimonialsData]);

  const handleListing = () => {
    navigate(RouteConstants.LISTTESTIMONIALS);
  };

  if (!testimonialsData.length) {
    return null;
  }

  return (
    <div className="testimonials-wrpr">
      <div className="container">
        <div className="row">
          <div className="col-md-12 p-0 text-center">
            <h2>
              <span className="speaker-pattern"></span>
              <a href="/testimonials"
              className="text-decoration-none text-reset"
              >Testimonials</a>
              <span className="speaker-pattern"></span>
            </h2>
          </div>
          <div className="col-md-12 pt-4 pb-2 mt-0" style={{ position: 'relative' }}>
            <div className="swiper-leftarrow">
              <div className="swiper-button-prev news-prev"></div>
            </div>
            <div ref={containerRef} className="swiper-container testimonials-slide">
              <Swiper
                ref={swiperRef}
                modules={[Autoplay, Navigation, Pagination]}
                spaceBetween={20}
                loop={true}
                speed={500}
                slidesPerView={3}
                loopedSlides={4}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: true,
                }}
                navigation={{
                  nextEl: '.swiper-button-next.news-next',
                  prevEl: '.swiper-button-prev.news-prev',
                }}
                pagination={{
                  el: '.swiper-pagination.news-pagination',
                  clickable: true,
                }}
                breakpoints={{
                  // 800: {
                  //   slidesPerView: 1,
                  //   spaceBetween: 0
                  // },
                  // 1000: {
                  //   slidesPerView: 2,
                  //   spaceBetween: 0
                  // },
                  // 1200: {
                  //   slidesPerView: 3,
                  //   spaceBetween: 20
                  // }
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20
                  },
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 30
                  },
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 40
                  },
                  
                }}
              >
                {testimonialsData.map((testimonial, index) => (
                  <SwiperSlide key={index} className="swiper-slide text-center">
                    <TestimonialCard 
                      content={testimonial.content}
                      name={testimonial.name}
                      photo={testimonial.photo}
                      color={testimonial.color}
                    />
                  </SwiperSlide>
                ))}
                <div className="swiper-pagination-wrpr">
                <div className="swiper-pagination news-pagination"></div>
              </div>
              </Swiper>
            </div>
            <div className="swiper-rightarrow">
              <div className="swiper-button-next news-next"></div>
            </div>
           
          </div>
          <div className="col-md-12 text-center section-btn-wrpr pb-0">
            <a className="nav-link pl-0 btn btn-sm btn-dark btn-yellow" onClick={handleListing}>
              View More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;