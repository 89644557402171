import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Select from '../Select/Select';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ErrorModal from '../ErrorModal/Errormodal';
import sessionQueries from '../../queries/sessionQueries';
import Loader from '../Loader/Loader';


const schema = yup.object().shape({
  name: yup.string().required('Venue Name is required'),
  place: yup.string().required('Venue Place is required'),
  status: yup.string().required('Status is required'),
});

export default function VenueForm() {
  const navigate = useNavigate();
  const { id } = useParams(); // Get the venue ID from the URL if it exists
  const [searchParams] = useSearchParams(); // Get the query parameters
  const isEditMode = Boolean(id);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, setValue, reset, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
      place: '',
      status: 'ACTIVE',
    }
  });

  const statusOptions = [
    { value: 'ACTIVE', label: 'ACTIVE' },
    { value: 'INACTIVE', label: 'INACTIVE' },
  ];

  useEffect(() => {
    if (isEditMode) {
      // Fetch venue data if in edit mode
      const name = searchParams.get('name') || '';
      const place = searchParams.get('place') || '';
      const status = searchParams.get('status') || 'ACTIVE';
     
      reset({
        name,
        place: place === 'null' ? '' : place, // Handle 'null' string
        status,
      });

    }
  }, [isEditMode, searchParams, reset]);



  const venueCreate = sessionQueries.useVenueCreateMutation(async (response) => {
  });

  const venueUpdate = sessionQueries.useVenueUpdateMutation(async (response) => {
});

  const onSubmit = async (data) => {
    try {
      if (isEditMode) {
        // Update existing venue
        // await venueUpdate.mutateAsync({ id, ...data });
        venueUpdate.mutateAsync({ id, ...data }).then( async (response) => {
            if (response?.message==='Success') {
                navigate('/venuelist');
            }
            else{
                setIsErrorModalOpen(true);
            }
            });
      } else {
        // Create new venue
        // await venueCreate.mutateAsync(data);
        await venueCreate.mutateAsync(data).then( async (response) => {
            if (response?.message==='Success') {
              navigate('/venuelist');
            }
            else{
              setIsErrorModalOpen(true);
            }
          });
      }
      navigate('/venuelist');
    } catch (error) {
      setErrorMessage(error.message || 'An error occurred while saving the venue.');
      setIsErrorModalOpen(true);
    }
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  const inputClassName = "w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary";

  return (
    <div className="container mx-auto p-6">
      <Breadcrumb pageName={isEditMode ? "Edit Venue" : "Add Venue"} />

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          <div>
            <label htmlFor="venueName" className="block text-sm font-medium text-gray-700">
              Venue Name
            </label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => <input {...field} className={inputClassName} />}
            />
            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>}
          </div>

          <div>
            <label htmlFor="venuePlace" className="block text-sm font-medium text-gray-700">
              Venue Place
            </label>
            <Controller
              name="place"
              control={control}
              render={({ field }) => <input {...field} className={inputClassName} />}
            />
            {errors.place && <p className="text-red-500 text-sm mt-1">{errors.place.message}</p>}
          </div>

          <div>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  label="Status"
                  options={statusOptions}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setValue('status', e.target.value);
                  }}
                  value={field.value}
                  required={true}
                />
              )}
            />
            {errors.status && <p className="text-red-500 text-sm mt-1">{errors.status.message}</p>}
          </div>

          <button
            type="submit"
            className="w-full px-4 py-3 font-bold text-white bg-blue-500 rounded-md hover:bg-blue-700 focus:outline-none focus:shadow-outline"
          >
            {isEditMode ? 'Update Venue' : 'Add Venue'}
          </button>
        </form>
      </div>
      
      <ErrorModal
        isOpen={isErrorModalOpen}
        onClose={closeErrorModal}
        errorMessage={errorMessage}
      />
    </div>
  );
}