import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller} from "react-hook-form";
import { useNavigate, useParams ,useLocation} from "react-router-dom";
import userQueries from '../../queries/userQueries';
import Loader from '../Loader/Loader';
import RichTextEditor from "../Rte/Rteeditor";

const EditSpeaker = () => {
    const [gender, setGender] = useState("");
    const [usernameExist, setUsernameExist] = useState('');
    const [usernameerror, setUsernameerror] = useState(false);
    const [susmsg, setSusmsg] = useState(false);
    const [gendererror, setGendererror] = useState(false);
    const [imageError, setImageError] = useState('');
    const [currentImage, setCurrentImage] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUploadSuccess, setImageUploadSuccess] = useState(false);
    const [currentPublishStatus, setCurrentPublishStatus] = useState(0);
    const [loading, setLoading] = useState(false); // Add loading state
    const imageRef = useRef(null);
    const [currentImageUrl, setCurrentImageUrl] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);

    const { id } = useParams();
    const navigate = useNavigate();

    const { register, reset, handleSubmit,getValues, control, formState: { errors } } = useForm();

    const Speakerdata = userQueries.useUpdatespeakerMutation(
        (response) => {
            setLoading(false); // Stop the loader once data is fetched
            if (response?.data) {
                let datas = ({
                    id: id,
                    heading: response?.data.heading,
                    email: response?.data.email,
                    phone: response?.data.phone,
                    content: response?.data.content,
                    publishStatus: response?.data.publishStatus,
                    displayorder: response?.data.display_order
                });
                reset({ ...datas });
                setGender(response?.data.gender);
                setCurrentImage(response?.data.photo || null);
                setCurrentPublishStatus(response?.data.status || 'Unpublish');
               
                setCurrentImageUrl(response?.data.photo ? `${process.env.REACT_APP_BASE_URL}/${response.data.photo}` : null);
            }
        }
    );

    const UpdateUserImage = userQueries.useSpeakerimageMutation(
        (response) => {
            if (response?.message === "Profile image uploaded successfully") {
                setImageUploadSuccess(true);
                setTimeout(() => {
                    setImageUploadSuccess(false);
                }, 3000);
            }
        }
    );

    useEffect(() => {
        setLoading(true); // Set loader to true when fetching data
        Speakerdata.mutateAsync({ id });
    }, [id]);

    const location = useLocation();
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    // Retrieve current page from navigation state
    const navigationState = location.state;
    if (navigationState?.currentPage !== undefined) {
      setCurrentPage(navigationState.currentPage);
      localStorage.setItem('speakerListCurrentPage', navigationState.currentPage.toString());
    }
  }, [location]);

    const editSpeaker = userQueries.useUpdatespeakerMutation(
        (response) => {
            setLoading(false); // Stop the loader after submission
            if (response?.message === "Speaker updated successfully") {
                setSusmsg(true);
                alert(response?.data.status === 'Unpublish' ? "Saved as draft" : "Published successfully");
                if (selectedImage) {
                    const formData = new FormData();
                    formData.append('speakers', selectedImage);
                    formData.append('userId', id);
                    UpdateUserImage.mutateAsync(formData);
                }
                // localStorage.setItem('speakerListCurrentPage', '1');
                localStorage.setItem('speakerListCurrentPage', currentPage.toString());
                navigate('/speakerlisting');
               
            }
            else if(response?.message === "Phone already exists")
            {
                alert("User Exists with this phone number")
            }
            else if(response?.message === "Email already exists")
            {
                alert("User Exists with this Email")
            }
            else if(response?.message === "Phone or Email already exists")
            {
                alert("User Exists with this Phone or Email")
            }
        }
    );

    // const handleImageChange = (e) => {
    //     if (e.target.files && e.target.files[0]) {
    //         setSelectedImage(e.target.files[0]);
    //         const objectUrl = URL.createObjectURL(file);
    //         setPreviewUrl(objectUrl);
    //     }
    // };
    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setSelectedImage(file);
            
            // Create a preview URL for the newly selected image
            const objectUrl = URL.createObjectURL(file);
            setPreviewUrl(objectUrl);
        }
    };

    const handleFormSubmit = (data, publishStatus) => {
       
        if(publishStatus === "Publish"){

            if (!imageRef.current.files[0] && !currentImage) {
                setImageError("Image is required.");
                return; // Stop here if no image
            } else {
                setImageError(""); // Clear error if image is selected
            }
        }
        const datavalues = {
            id: id,
            heading: data.heading,
            email: data.email,
            phone: data.phone,
            content: data.content,
            status: publishStatus,
            display_order: data.displayorder
        };
        editSpeaker.mutateAsync(datavalues);
       
    };

    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);

    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Update Speaker
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">
                <div className="mb-5 col-span-2">
                    <label htmlFor="heading" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Heading</label>
                    <input type="text" id="heading" {...register("heading", { required: "Heading is required" })} name="heading"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"/>
                    {errors.heading && <div className="text-red-600 text-s">Heading is required</div>}
                </div>

                <div className="mb-5 col-span-2">
                    <label htmlFor="content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Content</label>
                    <Controller
                    name="content"
                    control={control}
                    rules={{ required: "Content is required" }}
                    render={({ field }) => (
                        <RichTextEditor
                            {...field}
                            id="content"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                            rows="4"
                        />
                    )}
                    />
                    {errors.content && <div className="text-red-600 text-s">content is required</div>}
                </div>

                <div className="mb-5 col-span-2">
                    <label htmlFor="image" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Profile Image
                    </label>
                  
                     {/* Image Preview Section */}
                    <div className="mb-4">
                        {(previewUrl || currentImageUrl) && (
                            <div className="relative w-48 h-48 mb-4">
                                <img
                                    src={previewUrl || currentImageUrl}
                                    alt="Speaker preview"
                                    className="w-full h-full object-cover rounded-lg shadow-md"
                                />
                            </div>
                        )}
                    </div>
                    <input
                        type="file"
                        id="image"
                        ref={imageRef}
                        onChange={handleImageChange}
                        accept="image/*"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    /> 
                    <div className="form-text text-warning small">
                    Recommended image size: less than 256 KB.
                  </div>
                   
                    {imageUploadSuccess && (
                        <div className="mt-2 text-sm text-green-600">
                            Image uploaded successfully!
                        </div>
                    )}
                    {imageError && <div className="text-red-600 text-s">{imageError}</div>}
                </div>

                <div className="mb-5 col-span-1">
                <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
                <input
                    type="tel"
                    id="phone"
                    {...register("phone", {
                        
                        pattern: {
                            value: /^(?:\+91|91)?\d{10}$/,
                            message: "Please enter a  valid phone number"
                        }
                    })}
                    name="phone"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                />
                {errors.phone && <div className="text-red-600 text-sm">{errors.phone.message}</div>}
            </div>


                <div className="mb-5 col-span-1">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                    <input type="email" id="email" {...register("email", {  pattern: { 
                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, message: "Enter a valid email address" }})} name="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5"
                    />
                    {errors.email && <div className="text-red-600 text-s">Please Enter email</div>}
                    {usernameerror && <div className="text-red-600 text-s">Please Enter email</div>}
                    {usernameExist && <div className="text-red-600 text-s">Email already exists</div>}
                </div>
                <div className="mb-5">
                    <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                    <input 
                        type="number" 
                        id="displayorder" 
                        {...register("displayorder", { required: false })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    
                </div>

                {/* Draft and Publish buttons */}
                <button 
                    type="button" 
                    onClick={() => handleFormSubmit(getValues(), 'Unpublish')}
                    className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
                >
                    Save as draft
                </button>

                <button 
                    type="button"
                    onClick={handleSubmit((data) => handleFormSubmit(data, 'Publish'))}
                    className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save and publish
                </button>

                {susmsg && (
                    <div className="text-green-600 text-lg ml-6 text-center inline-block whitespace-nowrap col-span-2">
                        Speaker updated successfully
                    </div>
                )}

                {/* Loader Spinner */}
                {loading && (
                    <Loader />
                )}
            </form>
        </>
    );
};

export default EditSpeaker;
