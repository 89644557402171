import React, {useEffect, useState} from 'react';
import { Check, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import qrcode from '../images/qr-sample.jpg';
import Klflogo from '../images/klf-logo.png';
import Navbar from './Navbar';
import Footer from './Footer';
import { toPng } from 'html-to-image'; 
import { message, message as messages } from "antd";

const AttendeSuccessPage = () => {

  const navigate = useNavigate();
  const [qrcode, setQrcode] = useState({})
  const [messageApi, contextHolder] = message.useMessage();

  const handleHome = () => {
      navigate('/');
  }

  const success = () => {
    messageApi.open({
      type: 'info',
      content: 'A ticket has been mailed to your email id also. Please check spam folder if you are not able to find it',
      className: 'custom-class',
      style: {
        marginTop: '10vh',
      },
      duration: 10, // sets the duration to 10 seconds
    });
  };
  

  const downloadStampCard = () => {

    
    const stampCard = document.getElementById('stampCard'); // Get the stamp card element

    // Check if the stampCard exists
    if (!stampCard) {
        console.error('Stamp card element not found');
        return; // Exit if the element is not found
    }

    toPng(stampCard)
        .then((dataUrl) => {
            const link = document.createElement('a');
            link.download = 'entry-pass.png'; // Set the filename
            link.href = dataUrl;
            document.body.appendChild(link); // Append link to body
            link.click(); // Trigger the download
            document.body.removeChild(link); // Remove link from body
            success();
        })
        .catch((error) => {
            console.error('Error downloading the entry-pass:', error);
        });
};

 

  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: true
  });

  
  useEffect(() => {
    const qrData = JSON.parse(sessionStorage.getItem('qrdata'));
    setQrcode(qrData)
  }, []);

  return (
   <>
   <Navbar  />
   {contextHolder}
    <div className="innerbanner paymentsuccess container-fluid">
        <div className="container">
            <div className="row">
            <div className="col-md-12 text-center">
                <i className="icon-thumb_up"></i>
                <div className="thankyou-hding">Thank You!</div>
                <div className="payment-done">
                Your Registration's has been successfully processed
                <p>A ticket has been mailed to your email id also. Please check spam folder if you are not able to find it</p>
                </div>
                <div className="order-date">
                <span>Order Date:</span> <b>{formattedDate}</b>
                </div>
            </div>
            </div>
        </div>
        </div>


        <div className="container-fluid payment-cntnt-wrpr">
        <div className="container">
            <div className="row">
            <div className="col-md-2 pt-5 pb-5 pay-col-1"></div>

            <div className="col-md-3 pt-5 pb-5 pay-col-2 my-auto reg-message">
                <p>
                This is to confirm your registration for the Kerala Literature festival taking place at{' '}
                <span className="yellow">Kozhikode beach</span> on <span className="yellow">23</span>,{' '}
                <span className="red">24</span>, <span className="green">25</span> and{' '}
                <span className="blue">26</span> <span className="orange">January 2025.</span>
                </p>
                <p>
                Your registration is as a Attendee. Looking forward to seeing you at the Lit fest.
                </p>
                <div className="line"></div>
            </div>

            <div className="col-md-5 pt-5 pb-5 pay-col-2 d-flex justify-content-center">
                <div  id='stampCard' className="stamp-card">
                <div className="stamp-card-body">
                    <div className="stamp-card-wrpr">
                    <div className="order-id">
                        <b>
                        KLF ID:<br />{qrcode?.mergedData}
                            
                        </b>
                    </div>
                    <div className="reg-name">{qrcode?.name}</div>
                    <img className="qr-code" src={qrcode?.qrCode} alt="QR Code" />
                    <div className="entrypass">Entry Pass</div>
                    <div className="klf-logo">
                        <img src={Klflogo} alt="Kerala Literature Festival" />
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div className="col-md-2 pt-5 pb-5 pay-col-3"></div>
            </div>
        </div>

          <div className="text-center">
                <button className="btn btn-primary" onClick={downloadStampCard}>
                    Download Entry Pass
                </button>
          </div>
        </div>

      

        <Footer />

   </>
  );
};

export default AttendeSuccessPage;