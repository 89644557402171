import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { useScrollToTop } from '../hooks/ScrollTop';
import { useNavigate } from 'react-router-dom';

const RegisterPage = () => {

    const navigate = useNavigate();

    const handeDelegate = () => {
        navigate('/delegate-register');
    }

    const handeStudent = () => {
        navigate('/student-register');
    }
    const handeAttendee = () => {
        navigate('/attendee-register');
    }


    useScrollToTop();
    return (
        <>

        <Navbar />
            <div className="innerbanner registration registration-page container-fluid">
            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <span className="logo-strips"></span>
                    <h2>KLF <span>Registration</span></h2>
                </div>
                </div>
            </div>
            </div>


<div className="container registration-type-popup" id="registrationType">
  <div className="row">
    <div className="col-md-12">
      <div className="modal-content">
        <div className="modal-body">
          <div className="row popup-columns">
            <div className="col-md-12">
              <div className="dele-perks">
                <strong>KLF delegate registration perks</strong>
                <br />
                <ul>
                  <li>Designer tote bag</li>
                  <li>Designer notebook</li>
                  <li>Delegate Tag</li>
                  <li>Designer Bookmark Kit</li>
                  <li>Gift coupon worth 150 at Festival bookshop</li>
                  <li>Gift coupon worth 150 at DC Books online shop</li>
                  <li>Exclusive delegate sessions</li>
                  <li>Festival Calendar</li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="popup-boxset">
                <h3>
                  <span></span> Delegate Registration
                </h3>
                <div className="why-delegate">
                  <h4>Why be a KLF Delegate?</h4>
                  <p>
                    By being a KLF Delegate you will have access to our finest
                    speakers and exclusively curated sessions, along with
                    priority book signing access, gift coupons, and other
                    goodies.
                  </p>
                  <p>
                    You can be a delegate by paying{" "}
                    <span className="rupees orange">Rs. 1399.</span> <br />
                    Early Bird Offer{" "}
                    <span className="rupees yellow">@ Rs 1199 </span> valid till
                    20th, Dec 2024.
                  </p>
                </div>
              </div>
              <div className="delegate-btn-cntnr">
                <a
                  onClick={handeDelegate}
                  className="btn btn-lg btn-transparent btn-reg"
                >
                  Delegate Registration
                </a>
              </div>
            </div>
            <div className="or-cntnr">OR</div>
            <div className="col-md-4">
              <div className="popup-boxset">
                <h3>
                  <span></span> Student Registration
                </h3>
                <div className="why-delegate">
                  <p>Be a student delegate @ Rs. 699 only</p>
                </div>
              </div>
              <div className="delegate-btn-cntnr">
                <a
                  onClick={handeStudent}
                  className="btn btn-lg btn-transparent btn-reg"
                >
                  Student Registration
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="popup-boxset">
                <h3>
                  <span></span> Attendee Registration
                </h3>
                <div className="why-delegate">
                  <p>
                    Entry to KLF 2025 is free <br />
                    but registration is mandatory.
                  </p>
                </div>
              </div>
              <div className="delegate-btn-cntnr">
                <a
                  onClick={handeAttendee}
                  className="btn btn-lg btn-transparent btn-reg"
                >
                  Attendee Registration
                </a>
              </div>
            </div>
            <div className="or-cntnr another-or">OR</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<Footer />

        </>
    )


}
export default RegisterPage;