import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import CryptoJS from 'crypto-js';
import { countriesData } from './countryData';
import Navbar from './Navbar';
import Footer from './Footer';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import InitModal from './initModal';
import authQueries from '../queries/authQueries';
import siteQueries from '../queries/siteQueries';
import { useNavigate } from 'react-router-dom';
import BootstrapLoader from '../components/Loader/BootstrapLoader';
import { useScrollToTop } from '../hooks/ScrollTop';
import FeedbackModal from './FeedbackModal';
import AgeInput from '../components/Agedrop/AgeSelect';

// CCAvenue credentials (Warning: Exposing these on the client side is not secure)
const WORKING_KEY = process.env.REACT_APP_CCAVENUE_WORKING_KEY;
const ACCESS_CODE = process.env.REACT_APP_CCAVENUE_ACCESS_CODE;
const MERCHANT_ID = process.env.REACT_APP_CCAVENUE_MERCHANT_ID;

const CustomAlert = ({ title, message }) => (
  <div className="alert alert-success" role="alert">
    <h4 className="alert-heading">{title}</h4>
    <p>{message}</p>
  </div>
);
const schema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    phone: yup.string().min(10, 'Phone number must be at least 10 digits').required('Phone number is required'),
    termsAccepted: yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  });
export default function StudentForm() {

  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, reset, formState: { errors, isValid }, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      dob: '2024-11-02 00:00:00+00',
      gender: '',
      pin_code: '',
      country: "India",
      state: 'Kerala',
      address:'',
      college:'',
      district:'',
      termsAccepted: true
    },
    mode: 'onChange'
  });

  const navigate = useNavigate();

    const handleTerms = () => {
        navigate('/terms-conditions');
    }


  const [submitted, setSubmitted] = useState(false);
  const [isFormFilled, setIsFormFilled] = useState(false);
  const [states, setStates] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [userCreationStatus, setUserCreationStatus] = useState(null);
  const watchAllFields = watch();
  const watchCountry = watch("country");
  const [code, setCode] = useState('in');
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [terms, setTerms] = useState(true)

  const [isFeedbackOpen, setFeedbackOpen] = useState(false);
  const handleFeedbackOpen = () => setFeedbackOpen(true);
  const handleFeedbackClose = () => setFeedbackOpen(false);
  const [feedBacksubmit, setFeedBacksubmit] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    const allFieldsFilled = Object.values(watchAllFields).every(field => field !== '' && field !== false);
    setIsFormFilled(allFieldsFilled);
  }, [watchAllFields]);

  useEffect(() => {
    const selectedCountry = countriesData.find(country => country.name === watchCountry);
    if (selectedCountry) {
      // Sort states alphabetically before setting them
      const sortedStates = [...selectedCountry.states].sort((a, b) => a.localeCompare(b));
      setStates(sortedStates);
      setValue("state", ""); // Reset state when country changes
    } else {
      setStates([]);
    }
  }, [watchCountry, setValue]);

    // Sort the countries alphabetically
    const sortedCountries = [...countriesData].sort((a, b) => 
    a.name.localeCompare(b.name)
    );

  const handleUserPaidStatus = async () => {
    const registrationData = JSON.parse(sessionStorage.getItem('registrationData'));
    const paymentRes = JSON.parse(sessionStorage.getItem('paymentRes'));

    if (paymentRes) {
      const response = await userPaidstatus.mutateAsync(registrationData);
      if (response.success) {
        await ZohoCrm.mutateAsync({
          "firstName": registrationData.first_name,
          "lastName": registrationData.last_name,
          "email": registrationData.email,
          "Phone": registrationData.phone,
          "Zip_Code": registrationData.pin_code,
          "Gender": registrationData.gender,
          "State": registrationData.state,
          "City": registrationData.district,
          "Full_Address": registrationData.address,
          "Amount": 699,
          "Type_Of_Registration":["Students"]
        });
        setIsLoading(false)
        sessionStorage.setItem('qrdata', JSON.stringify(response));
        sessionStorage.removeItem('paymentRes');
        navigate('/success');
      }else{
        sessionStorage.removeItem('paymentRes');
        navigate('/failed');
      }
      console.log("registrationData", registrationData);
    }
  };

  useEffect(() => {
    handleUserPaidStatus();
  }, []);

  const encrypt = (plainText) => {
    const key = CryptoJS.MD5(WORKING_KEY).toString();
    const iv = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f');
    const encrypted = CryptoJS.AES.encrypt(plainText, CryptoJS.enc.Hex.parse(key), { 
      iv: iv, 
      mode: CryptoJS.mode.CBC, 
      padding: CryptoJS.pad.Pkcs7 
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  };

  const decrypt = (encryptedText) => {
    const key = CryptoJS.MD5(WORKING_KEY).toString();
    const iv = CryptoJS.enc.Hex.parse('000102030405060708090a0b0c0d0e0f');
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: CryptoJS.enc.Hex.parse(encryptedText) },
      CryptoJS.enc.Hex.parse(key),
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  const userExist = siteQueries.useDelagateExistMutation(async (response) => {
    
  });

  const userPaidstatus = siteQueries.useDelagatePaidstatusMutation(async (response) => {
    
  });

  const useDelegateCreate = siteQueries.useDelegateCreateMutation(async (response) => {
    
  });


  const ZohoCrm = authQueries.useZohoCrmMutation(async (response) => {
    
  });



  const onSubmit = async  (data) => {

    const orderId = `ORDER_STUDENT_DELEGATE_${Date.now()}`;

    const submissionData = {
      ...data,
      username: data.email,
      orderId: orderId,
      role: "STUDENT", 
      age : data?.age?.value,
    };

    setIsLoading(true)
   
    const amount = '699'; 

    let address=data.address
    if(data.address==='')
    {
      address='Kerala Lit Festival'

    }

    if(data.gender===''){
      submissionData.gender='PREFER NOT TO RESPOND'
    }

    await useDelegateCreate.mutateAsync(submissionData).then((response) => {

    if (response.message === 'User created successfully') {

      const redirectUrl = `${window.location.origin}${window.location.pathname}`;
      const cancelUrl = `${window.location.origin}${window.location.pathname}?status=cancelled`;

      const requestData = `merchant_id=${MERCHANT_ID}&order_id=${orderId}&currency=INR&amount=${amount}&redirect_url=${redirectUrl}&cancel_url=${cancelUrl}&billing_name=${data.first_name} ${data.last_name}&billing_address=${address}&billing_city=${data.district}&billing_state=${data.state}&billing_zip=${data.pin_code}&billing_country=${data.country}&billing_tel=${data.phone}&billing_email=${data.email}`;

      // Store the form data in sessionStorage for later use
      sessionStorage.setItem('registrationData', JSON.stringify(data));
      sessionStorage.setItem('paymentRes', JSON.stringify(data));

      const encryptedData = encrypt(requestData);

      const form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';

      const encReqField = document.createElement('input');
      encReqField.type = 'hidden';
      encReqField.name = 'encRequest';
      encReqField.value = encryptedData;

      const accessCodeField = document.createElement('input');
      accessCodeField.type = 'hidden';
      accessCodeField.name = 'access_code';
      accessCodeField.value = ACCESS_CODE;

      form.appendChild(encReqField);
      form.appendChild(accessCodeField);

      document.body.appendChild(form);
      form.submit();
    } else if(response.message === 'User already exists') {

      const redirectUrl = `${window.location.origin}${window.location.pathname}`;
      const cancelUrl = `${window.location.origin}${window.location.pathname}?status=cancelled`;

      const requestData = `merchant_id=${MERCHANT_ID}&order_id=${orderId}&currency=INR&amount=${amount}&redirect_url=${redirectUrl}&cancel_url=${cancelUrl}&billing_name=${data.first_name} ${data.last_name}&billing_address=${address}&billing_city=${data.district}&billing_state=${data.state}&billing_zip=${data.pin_code}&billing_country=${data.country}&billing_tel=${data.phone}&billing_email=${data.email}`;

      // Store the form data in sessionStorage for later use
      sessionStorage.setItem('registrationData', JSON.stringify(data));
      sessionStorage.setItem('paymentRes', JSON.stringify(data));

      const encryptedData = encrypt(requestData);

      const form = document.createElement('form');
      form.method = 'POST';
      form.action = 'https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction';

      const encReqField = document.createElement('input');
      encReqField.type = 'hidden';
      encReqField.name = 'encRequest';
      encReqField.value = encryptedData;

      const accessCodeField = document.createElement('input');
      accessCodeField.type = 'hidden';
      accessCodeField.name = 'access_code';
      accessCodeField.value = ACCESS_CODE;

      form.appendChild(encReqField);
      form.appendChild(accessCodeField);

      document.body.appendChild(form);
      form.submit();
    }else{
      setIsLoading(false);
      setErrorMessage(response.message || 'Registration failed. Please try again.');
    }
  }).catch((error) => {
    setIsLoading(false);
    console.error("Error during registration:", error);
  });
  
  };


  const userCreate = authQueries.useUserCreateMutation(async (response) => {
    
  });

  const processPaymentResponse = async (encResp) => {
    const decryptedResponse = decrypt(encResp);
    const responseParams = new URLSearchParams(decryptedResponse);
    const responseObject = Object.fromEntries(responseParams.entries());
    setPaymentStatus(responseObject);

    console.log('Payment response:', responseObject);

    // Check if payment was successful
    if (responseObject.order_status === 'Success') {

      try {
        const registrationData = JSON.parse(sessionStorage.getItem('registrationData'));
        console.log('registrationData:', registrationData);
        const response = await userCreate.mutateAsync(registrationData);
        console.log("API response:", response);
        
        if (response.message === 'Success') {
          reset(); // Reset the form
          setSubmitted(true);
          console.log("Registration successful");
          // You might want to show a success message or redirect the user
        } else {
          setErrorMessage(response.message || 'Registration failed. Please try again.');
        }
      } catch (error) {
        console.error("Error during registration:", error);
        
        if (error.response) {
          setErrorMessage(error.response.data.message || 'An error occurred during registration.');
        } else if (error.request) {
          setErrorMessage('No response received from the server. Please try again.');
        } else {
          setErrorMessage('Email Already Exist.');
        }
      }
    } else {
      setUserCreationStatus('Payment was not successful, user not created');
    }
  };

  useScrollToTop();

  return (
    <>
    
      <Navbar handleOpen={handleOpen} handleClose={handleClose} />
      <div className="innerbanner registration container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <span className="logo-strips"></span>
              <h2>Student <span>Registration</span></h2>
            </div>
          </div>
        </div>
      </div>
{/* 
      <div className="why-delegate">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h5>Why be a KLF Delegate?</h5>
              <p class="mb-2">As a valued supporter of the Kerala Literature Festival (KLF), we invite you to be part of our Delegate Network. KLF has grown into Asia's largest literature festival, attracting half-a-million participants, thanks to your enthusiasm and encouragement. This achievement is further celebrated with Kozhikode being recognized as India's first UNESCO 'City of Literature'.</p>
				
            </div>
          </div>
        </div>
      </div> */}
      {isLoading ? (
      <BootstrapLoader />

       ) : (
      <div className="container">
        <div className="row">
          <div className="col-md-7 pt-5 pb-5">
            {!paymentStatus ? (
              <form onSubmit={handleSubmit(onSubmit)} className="delegate-form pt-5 pb-5">
                <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <Controller
                      name="first_name"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="First name*"
                        />
                      )}
                    />
                    {errors.first_name && <div className="invalid-feedback">{errors.first_name.message}</div>}
                  </div>
                  <div className="col-md-6">
                    <Controller
                      name="last_name"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Last name*"
                        />
                      )}
                    />
                    {errors.last_name && <div className="invalid-feedback">{errors.last_name.message}</div>}
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-6">
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                          type="email"
                          placeholder="Email*"
                        />
                      )}
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                  </div>
                  <div className="col-md-6">
                    <Controller
                      name="phone"
                      control={control}
                      className={`form-control ${errors.phone ? 'is-invalid' : ''}`}
                      render={({ field: { onChange } }) => (
                        <PhoneInput
                            className={`phoneinput ${errors.phone ? 'is-invalid' : ''}`}
                            enableSearch={true}
                            country={code}
                            // value={appStore?.appData?.mobile}
                            onChange={(value, country, e, formattedValue) => {
                              setCode(country.countryCode)
                              onChange(value)
                            }}
                        /> 
                      )}
                      rules={{ required: true }}
                    />
                    {errors.phone && <div className="invalid-feedback">{errors.phone.message}</div>}
                  </div>
                </div>

                <div className="row mt-5">
                 
                  <AgeInput control={control} errors={errors} />
                 
                  <div className="col-md-3 gender-wrpr">
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <select
                          {...field}
                          className={`form-control ${errors.gender ? 'is-invalid' : ''}`}
                        >
                          <option value="">Select Gender</option>
                          <option value="MALE">MALE</option>
                          <option value="FEMALE">FEMALE</option>
                          <option value="OTHERS">OTHERS</option>
                          <option value="PREFER NOT TO RESPOND">PREFER NOT TO RESPOND</option>
                        </select>
                      )}
                    />
                    {errors.gender && <div className="invalid-feedback">{errors.gender.message}</div>}
                  </div>

                <div className="col-md-6">
                <div className='countryselect-wrpr'>
                  <Controller
                    name="country"
                    control={control}
                    
                    render={({ field }) => (
                      <select
                        {...field}
                        className={`form-control ${errors.country ? 'is-invalid' : ''}`}
                        
                      >
                        <option value="">Select Country</option>
                        {sortedCountries.map((country, index) => (
                          <option key={index} value={country.name}>{country.name}</option>
                        ))}
                      </select>
                    )}
                  />
                  </div>
                  {errors.country && <div className="invalid-feedback">{errors.country.message}</div>}
                </div>


                  {/* <div className="col-md-4">
                    <label htmlFor="pin_code">Pin Code:</label>
                    <Controller
                      name="pin_code"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.pin_code ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Enter pin code"
                        />
                      )}
                    />
                    {errors.pin_code && <div className="invalid-feedback">{errors.pin_code.message}</div>}
                  </div> */}
                </div>

               
              <div className="row mt-5">

                <div className="col-md-4 state-wrpr">
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <select
                        {...field}
                        className={`form-control ${errors.state ? 'is-invalid' : ''}`}
                        disabled={!watchCountry}
                      >
                        <option value="">Select State</option>
                        {states.map((state, index) => (
                          <option key={index} value={state}>{state}</option>
                        ))}
                      </select>
                    )}
                  />
                  {errors.state && <div className="invalid-feedback">{errors.state.message}</div>}
                </div>

                <div className="col-md-4 district-wrpr">
                    <Controller
                      name="district"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.pin_code ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Enter District"
                        />
                      )}
                    />
              
                </div>
              </div>
              <div className="row mt-5">
              <div className="col-md-12">
                  <label htmlFor="state">School/College:</label>
                    <Controller
                      name="college"
                      control={control}
                      render={({ field }) => (
                        <input
                          {...field}
                          className={`form-control ${errors.pin_code ? 'is-invalid' : ''}`}
                          type="text"
                          placeholder="Enter Your School/College"
                        />
                      )}
                    />
                </div>
                </div>

              </div>
                <div className="row mt-5">
                  <div className="col-md-6">
                    <label>
                      <Controller
                        name="termsAccepted"
                        control={control}
                        render={({ field }) => (
                          <input
                          onClick={()=>{setTerms(terms?false:true)}}
                          type="checkbox"
                          checked={terms}
                            {...field}
                            className={errors.termsAccepted ? 'is-invalid' : ''}
                          />
                        )}
                      />
                      <a className="terms"  target='_blank' href='/terms-conditions'>Terms & Conditions</a>
                    </label>
                    {errors.termsAccepted && <div className="invalid-feedback">{errors.termsAccepted.message}</div>}
                  </div>
                  <div className="col-md-6">
                    <div className="text-end">
                    <button 
                      className="btn btn-primary btn-registration" 
                      type="submit"
                      disabled={!isValid}
                    >
                      Register & Pay <i className="icon-arrow-right1"></i>
                    </button>
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div  className="col-md-6">

                   
                  </div>
                  <div className="col-md-6" style={{display:'none'}}>
                    <div className="text-end">
                    <a onClick={handleFeedbackOpen} className="btn btn-transparent btn-feedback">
                    Need help ? Please contact support
                    </a>
                    </div>
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-md-12">
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                  </div>
                </div>
              </form>
            ) : (
              <div>
                <h2 className="text-xl font-semibold mb-2">Payment Response</h2>
                <pre className="bg-gray-100 p-4 rounded">
                  {JSON.stringify(paymentStatus, null, 2)}
                </pre>
              </div>
            )}
          </div>
         
          <div className="col-md-5 pt-5 pb-5">
           <BenefitsTable /> 
          </div>
        </div>
      </div>
       )}
      {submitted && (
        <CustomAlert 
          title="Success" 
          message="Your registration has been submitted successfully!"
        />
      )}

      {feedBacksubmit && (
        <CustomAlert 
          title="Success" 
          message="Your feedback has been submitted successfully!"
        />
      )}
      <InitModal isOpen={isOpen} onClose={handleClose} />
      <Footer />
      <FeedbackModal  setFeedBacksubmit={setFeedBacksubmit}  isOpen={isFeedbackOpen} onClose={handleFeedbackClose} />
    </>
  );
}

const BenefitsTable = () => {
  const perks = [
    "Designer tote bag",
    "Designer notebook",
    "Delegate Tag",
    "Gift coupon worth 150 at Festival bookshop",
    "Gift coupon worth 150 at DC Books online shop",
    "Exclusive delegate sessions",
    "Designer Bookmark Kit"
  ];
    return (
        <div className="benefits-wrpr">
        <table className="table">
          <thead>
            <tr>
              <td className="klf-drp">KLF Delegate Registration Perks</td>
              <td style={{textAlign: 'center'}}><span>Delegates</span></td>
              <td style={{textAlign: 'center'}}><span>Students</span></td>
              <td style={{textAlign: 'center'}}><span>Attendees</span></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Designer tote bag</td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-close"></i></td>
            </tr>
            <tr>
              <td>Designer notebook</td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-close"></i></td>
            </tr>
            <tr>
              <td>Delegate Tag</td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-close"></i></td>
            </tr>
            <tr>
              <td>Gift coupon worth 150 at Festival bookshop</td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-close"></i></td>
              <td className="text-center"><i className="icon-close"></i></td>
            </tr>
            <tr>
              <td>Gift coupon worth 150 at DC Books online shop</td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-close"></i></td>
            </tr>
            <tr>
              <td>Exclusive&nbsp;delegate&nbsp;sessions</td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-close"></i></td>
            </tr>
            <tr>
              <td>Designer Bookmark Kit</td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-check"></i></td>
              <td className="text-center"><i className="icon-close"></i></td>
            </tr>
            <tr>
            <td>Festival Calendar</td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-check"></i></td>
            <td className="text-center"><i className="icon-check"></i></td>
          </tr>
          </tbody>
        </table>
      </div>
  );
};
