import React, {useEffect, useState} from 'react'
import Footer from './Footer';
import Navbar from './Navbar';
import InitModal from './initModal';
import { useScrollToTop } from '../hooks/ScrollTop';
import siteQueries from '../queries/siteQueries';
import Pagination from '../common/Pagination/Pagination';
import Loader from '../components/Loader/Loader';
import { useNavigate} from 'react-router-dom';

export default function Listtestimonials() {

    const [isOpen, setIsOpen] = useState(false);
    const [testimonials, setTestimonials] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [totalPage, setTotalPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
 
    const navigate = useNavigate();
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    
    const newsData = siteQueries.useTestimonialsMutation(
        async (response) => {
          setTestimonials(response?.data?.items);
          setTotalCount(response?.data?.totalItems);
          setTotalPage(response?.data?.totalPages);
          setIsLoading(false);
        },
      );

      useEffect(() => {
        newsData.mutateAsync({
            page: currentPage,
            size: pageSize,
        })
        
      }, [currentPage, pageSize,]);

      const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      };

      const totalPages = totalPage;

     

      const decodeHtml = (html) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
      };

      const colors = ['yellow', 'red', 'green', 'orange']; // Define the color pattern

      const getColorByIndex = (index) => {
          return colors[index % colors.length]; // Cycle through colors
      };

  useScrollToTop();
  return (
    <div>
        {isLoading ? (
      <Loader /> 
    ) : (
        <>
        <Navbar handleOpen={handleOpen}  />
        <div className="innerbanner container-fluid">
            <div className="container">

                <div className="row">
                    <div className="col-md-12">
                    <span className="logo-strips"></span> <h2>Testimonials</h2>
                    </div>
                </div>
            </div>
        </div>


<div className="testimonials-wrpr testimonials-listing" >
	<div className="container" >
		<div className="row">
			
			<div className="col-md-12 pt-4 pb-2 mt-0" style={{position:'relative'}}>
				
				<div className="testimonials-slide row">
					
                {testimonials.map((item, index) => (
					   <div class="col-md-3 text-center">
							<div class={`card testimonial-card ${getColorByIndex(index)}`}>
								<div class="testim-user">
									<img 
                  style={{height: "200px", width: "300px", objectFit: "cover"}}
                  src={`${process.env.REACT_APP_BASE_URL}/${item.photo}`} />
								</div>
								<div class="testim-cntnt">
                  <div 
                  class="testimonial-para-wrpr"
               dangerouslySetInnerHTML={{
                 __html: decodeHtml(item.content)
               }}
             />
									
									<div class="testim-user-name">
                  {item.name}
									</div>
									<div class="testim-user-desig">
										{/* <!--Cheif Operating Officer--> */}
									</div>
								</div>
							</div>
							
						</div>
						
                    ))}
				
				</div>

                <div 
                className="d-flex justify-content-center mt-4">
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
                </div>
				
				
			</div>
			
			
		</div>
	</div>
</div>




          
                
        <Footer handleOpen={handleOpen} />

        <InitModal isOpen={isOpen} onClose={handleClose} />
        </>
    )}
    
    </div>
  )
}

