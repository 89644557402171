import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Loader from '../Loader/Loader';
import RouteConstants from '../../constant/Routeconstant';
import userQueries from '../../queries/userQueries';

const ListVideos = () => {
  const [videos, setVideos] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const videoslist = userQueries.useVideoslistMutation(
    async (response) => {
      setVideos(response?.data || []);
      setIsLoading(false);
    },
    {
      onError: (error) => {
        setError('Failed to fetch speaker list. Please try again later.');
        setIsLoading(false);
      }
    }
  );

  useEffect(() => {
    setIsLoading(true);
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    videoslist.mutate('');
  };

  const handleAddVideos = () => {
    navigate(RouteConstants.CREATEVIDEOS);
  };

  const deletevideos = userQueries.useDeletevideosMutation(
    (response) => {
      alert("Successfully deleted")
      navigate('/videoslist')
    }
  );

  const handleDelete = async (event, id) => {
    event.preventDefault();
    if (window.confirm('Are you sure you want to delete this item?')) {
      await deletevideos.mutateAsync(id);
    }
  }

  const publishvideos = userQueries.usePublishvideosMutation(
    (response) => {
      if (response?.message === "published successfully") {
        alert("published successfully")
        navigate('/videoslist');
      }
      else if (response?.message === "unpublished successfully") {
        alert("unpublished successfully")
        navigate('/videoslist');
      }
    }
  );

  const handlepublish = (id) => {
    publishvideos.mutateAsync(id);
  }

  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      <div className="flex justify-between items-center mb-4">
        <Breadcrumb pageName="Videos" />
        <div className="flex flex-col sm:flex-row gap-2">
          <button 
            className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 shadow-lg transition duration-300 ease-in-out transform hover:scale-105"
            title="Add Videos"
            aria-label="Add Videos"
            onClick={handleAddVideos}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          </button>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <div className="text-red-500 text-center p-4">{error}</div>
      ) : (
        <div className="flex flex-col">
          <div className="grid grid-cols-[0.5fr_0.5fr_.8fr_1.5fr_1fr_1fr] sm:grid-cols-[0.5fr_0.5fr_.8fr_1.5fr_1fr_1fr] border-b border-stroke bg-gray-800 dark:bg-meta-4">
            
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Sl no.</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Title</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Link</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Publish Status</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Display Order</div>
            <div className="p-2 text-xs font-medium uppercase xsm:text-sm">Actions</div>
          </div>

          {videos.map((videosdata, index) => (
            <div
              key={index}
              className="grid grid-cols-[0.5fr_0.5fr_.8fr_1.5fr_1fr_1fr] sm:grid-cols-[0.5fr_0.5fr_.8fr_1.5fr_1fr_1fr] border-b border-stroke bg-gray-200 dark:bg-meta-4"
            >
             
              <div className="p-2 text-xs xsm:text-sm">{index + 1}</div>
              <div className="p-2 text-xs xsm:text-sm overflow-hidden text-ellipsis">
                {videosdata.title}
              </div>
              <div className="p-2 text-xs xsm:text-sm overflow-hidden text-ellipsis">
                {videosdata.link}
              </div>
              <div className="p-2 text-xs xsm:text-sm">
                {videosdata.publishstatus === 0 ? (
                  videosdata.title && videosdata.link ? (
                    <button
                      className="px-3 py-1 text-xs font-medium text-red-500 bg-red-100"
                      onClick={() => handlepublish(videosdata.id)}
                    >
                      Unpublished
                    </button>
                  ) : (
                    <button
                      className="px-3 py-1 text-xs font-medium text-yellow-500 bg-yellow-100"
                      onClick={() => alert("This item is in draft mode and cannot be published until all required fields are filled.")}
                    >
                      Draft
                    </button>
                  )
                ) : (
                  <button
                    className="px-3 py-1 text-xs font-medium text-green-500 bg-green-100"
                    onClick={() => handlepublish(videosdata.id)}
                  >
                    Published
                  </button>
                )}
              </div>
              <div className="p-2 text-xs xsm:text-sm">{videosdata.displayorder}</div>
              <div className="p-2 text-xs xsm:text-sm flex items-center gap-3">
                <PencilSquareIcon
                  onClick={() => navigate(`/editvideos/${videosdata.id}`)}
                  className="h-6 w-6 text-black-600 cursor-pointer hover:text-blue-600"
                  aria-hidden="true"
                />
                <TrashIcon
                  onClick={(e) => handleDelete(e, videosdata.id)}
                  className="h-6 w-6 text-black-600 cursor-pointer hover:text-red-600"
                  aria-hidden="true"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListVideos;