import React, { useState, useEffect } from 'react';
import { Search,Edit } from 'lucide-react';
import userQueries from '../../queries/userQueries';
import { userStore } from '../../store/userStore';
import Pagination from '../../common/Pagination/Pagination';
import Loader from '../Loader/Loader';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import { DelegateView } from './delegateView';


const DelegateListing = () => {      
  const [users, setUsers] = useState([]);   
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { searchResults } = userStore();
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [dateFilter, setDateFilter] = useState(''); 
  const [paymentstatusFilter, setPaymentstatusFilter] = useState(''); 
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [isExporting, setIsExporting] = useState(false);


  
  
  
  const delegatelist = userQueries.useDelegateListing(
    async (response) => {
     
      setUsers(response?.data?.items);
      setTotalCount(response?.data?.totalItems);
      setTotalPage(response?.data?.totalPages);
      setIsLoading(false)
    
    },
    {
      onError: (error) => {
        setError('Failed to fetch user list. Please try again later.');
        setIsLoading(false);
      }
    }
  );

  // useEffect(() => {
  //   setIsLoading(true); 
  //   delegatelist.mutateAsync(); 
  // }, []);
  useEffect(() => {
    setIsLoading(true);
    fetchUsers();
  }, [currentPage, pageSize, searchTerm,dateFilter,startDate,endDate,paymentstatusFilter]);

  useEffect(() => {
    if (searchResults?.length > 0) {
      setUsers(searchResults);
      setTotalCount(searchResults?.length);
      setCurrentPage(0);
    }
  }, [searchResults]);

  useEffect(() => {
    if (!isLoading) { 
      setIsLoading(true);
      fetchUsers();
    }
  }, [currentPage, pageSize, searchTerm]);

  
  const buildParams = () => {
    const params = new URLSearchParams({
      page: currentPage,
      size: pageSize,
      search: searchTerm,
      filter: dateFilter,
      paymentstatus: paymentstatusFilter,
    });

    if (startDate && endDate) {
      params.append('startDate', startDate);
      params.append('endDate', endDate);
    }

    return params.toString();
  };
  const fetchUsers = () => {
    // const params = new URLSearchParams({
    //   page: currentPage,
    //   size: pageSize,
    //   search: searchTerm,
    //   filter: dateFilter,
    //   paymentstatus:paymentstatusFilter,
    // });

    // if (startDate && endDate) {
    //   params.append('startDate', startDate);
    //   params.append('endDate', endDate);
    // }

    // delegatelist.mutate(params.toString());
    // delegatelist.mutate(buildParams());
    const params = buildParams();
    delegatelist.mutate(params);
  };

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    setCurrentPage(0); 
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterChangepaymentstatus = (e) => {
    setPaymentstatusFilter(e.target.value);
    setCurrentPage(0);
  };
  const handleFilterChange = (e) => {
    setDateFilter(e.target.value);
    // Clear custom date range when using predefined filters
    if (e.target.value) {
      setStartDate('');
      setEndDate('');
    }
    setCurrentPage(0);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    // Clear predefined filter when using custom date range
    setDateFilter('');
    setCurrentPage(0);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    // Clear predefined filter when using custom date range
    setDateFilter('');
    setCurrentPage(0);
  };

  const clearDateFilters = () => {
    setStartDate('');
    setEndDate('');
    setDateFilter('');
    setCurrentPage(0);
  };

  const totalPages = totalPage;


  

  const exportFileDownload = userQueries.useDelegatesexportMutation(
    (response) => {
      if (response.fileName) {
        window.open(`${process.env.REACT_APP_BASE_URL}/user/downloaddelegates/${response.fileName}`, '_blank');
      }
    }
  );
  
  const exportFile = () => {
    setIsExporting(true);
    // Pass the same parameters used for filtering to the export endpoint
    exportFileDownload.mutateAsync(buildParams())
      .finally(() => {
        setIsExporting(false);
      });
  };
  
  return (
    <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
      
      <div className="flex justify-between items-center mb-4">
      <Breadcrumb pageName="Delegates"/>
      <div className="flex flex-col sm:flex-row gap-2">
      <button
          onClick={exportFile}
          disabled={isExporting}
          className='rounded-md border border-stroke bg-primary py-1 px-2 text-sm text-white'
        >
      {isExporting ? 'Exporting...' : 'Export'}
    </button>
        {/* Predefined Filters */}
        <select
          value={dateFilter}
          onChange={handleFilterChange}
          className="w-20 rounded-md border border-stroke bg-transparent py-1 px-2 text-sm"
        >
          <option value="">All</option>
          <option value="today">Today</option>
          <option value="thisWeek">This Week</option>
          <option value="thisMonth">This Month</option>
        </select>

        <select
          value={paymentstatusFilter}
          onChange={handleFilterChangepaymentstatus}
          className="w-20 rounded-md border border-stroke bg-transparent py-2 pl-3 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
        >
          <option value="Success">Success</option>
          <option value="Failure">Failure</option>
          <option value="Aborted">Aborted</option>
          <option value="Invalid">Invalid</option>
        </select>

        {/* Custom Date Range Filter */}
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-2">
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              className="rounded-md border border-stroke bg-transparent py-2 px-3 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
              placeholder="Start Date"
            />
            <span className="text-gray-500">to</span>
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              className="rounded-md border border-stroke bg-transparent py-2 px-3 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
              placeholder="End Date"
            />
          </div>
         
        </div>
      </div>
      </div>

      <div className="mb-4 flex items-center">
        <Search className="mr-2 h-5 w-5 text-gray-500" />
        <input
          type="text"
          placeholder="Search..."
          className="w-full rounded-md border border-stroke bg-transparent py-2 pl-10 pr-4 outline-none focus:border-primary dark:border-strokedark dark:bg-meta-4 dark:focus:border-primary"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

        {/* Display the count */}
        <div className="mb-4 text-gray-700 dark:text-gray-300">
        {users?.length} out of {totalCount} users found
      </div>
      {isLoading ? (
      <Loader /> 
    ) : (
      <>
        
      <div className="flex flex-col">
        <div className="border-b border-stroke text-black dark:border-strokedark grid grid-cols-[.5fr_.8fr_1.5fr_.8fr_.5fr_.5fr_.5fr_.5fr_.5fr] sm:grid-cols-[.5fr_.8fr_1.5fr_.8fr_.5fr_.5fr_.5fr_.5fr_.5fr] bg-gray-800 dark:bg-meta-4">
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
            
              Sl no.
            
          </div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
            
              Name
            
          </div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
            
              Email
            
          </div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
            
              Phone
            
          </div>
           <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
            
              Pincode
            
          </div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
            
              Amount
            
          </div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
            
              Registered on
            
          </div>
           <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
            
              Payment Status
            
          </div>
          <div className="p-2 text-xs font-medium uppercase xsm:text-sm">
            
              Action
            
          </div>
        
        </div>

       
          {users?.map((user, key) => (
            <div
              className={`border-b border-stroke dark:border-strokedark grid grid-cols-[.5fr_.8fr_1.5fr_.8fr_.5fr_.5fr_.5fr_.5fr_.5fr] sm:grid-cols-[.5fr_.8fr_1.5fr_.8fr_.5fr_.5fr_.5fr_.5fr_.5fr] bg-gray-800 dark:bg-meta-4${
                key === users?.length - 1
                  ? ''
                  : 'border-b border-stroke dark:border-strokedark'
              }`}
              key={key}
            >
            
              <div className="p-2 text-xs xsm:text-sm">
                {currentPage * pageSize + key + 1}
                
              </div>
              <div className="p-2 text-xs xsm:text-sm">
                  {user?.user_detail?.first_name} {user?.user_detail?.last_name}
                
              </div>
              <div className="p-2 text-xs xsm:text-sm truncate">
                {user?.user_detail?.email}
              </div>
              <div className="p-2 text-xs xsm:text-sm">
                {user?.user_detail?.phone}
              </div>
              <div className="p-2 text-xs xsm:text-sm">
                {user?.user_detail?.pin_code}
              </div>
              <div className="p-2 text-xs xsm:text-sm">
                {user?.delegate_payments?.[0]?.amount}
              </div>
              <div className="p-2 text-xs xsm:text-sm">
                {new Date(user?.user_detail?.createdAt).toLocaleDateString('en-IN')}
              </div>
              <div className="p-2 text-xs xsm:text-sm">
                {user?.delegate_payments?.[0]?.payment_status}
              </div>
              <div className="p-2 text-xs xsm:text-sm">
                <DelegateView userDetails={user}/>
              </div>
            </div>
        )
        )}
      </div>
     
      <div className="mt-4 flex justify-center">
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
     </div>
          </>
      )}
            </div>
  );
};

export default DelegateListing;
