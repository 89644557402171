import React, { useEffect, useState } from 'react';
import VenoBox from 'venobox';
import 'venobox/dist/venobox.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import siteQueries from '../queries/siteQueries';
import { useNavigate} from 'react-router-dom';

const Videos = () => {
  const  [videoData, setVideoData] = useState([]);
  const navigate = useNavigate();
  const size = 6;
  const page = 0;
  
    useEffect(() => {
        // Initialize VenoBox
        const venobox = new VenoBox({
          selector: '.venobox',
          numeration: true,
          infinigall: true,
          share: true,
          spinner: 'rotating-plane',
          spinColor: '#F4D03F',
          titleattr: 'data-title',
          titlePosition: 'top',
          titleStyle: 'bar',
          // Callbacks
          cb_init: function(obj) {
            console.log('VenoBox initialized');
          },
          cb_post_open: function(obj) {
            console.log('Video opened');
          },
          cb_pre_close: function(obj) {
            // Pause video when closing
            const iframe = document.querySelector('.vbox-content iframe');
            if (iframe) {
              const src = iframe.src;
              iframe.src = src;
            }
          }
        });
      
        // Cleanup function
        return () => {
          // Remove VenoBox if needed, or do any necessary cleanup
          // For now, we're just ensuring there are no dangling references.
        };
      }, [videoData]);

      useEffect(() => {
        video.mutateAsync({
          size: size,
          page: page
        });
      }, []);
      

      const video = siteQueries.useVideoMutation(
        async (response) => {
          setVideoData(response?.data?.items)
          
        },
      )
    
      const handleListing = () => {
        navigate('/videos');
      } 

  return (
    <div className="videos-wrpr pt-4 mt-4 pb-5 mb-2">
      <div className="container">
        <div className="row">
          <div className="col-md-12 p-0 text-center">
            <h2 data-aos="fade-up" data-aos-delay="100">
            <span className="speaker-pattern"></span>
            <a 
              class="text-decoration-none text-reset"
              href='/videos'> 
              VIDEOS
              </a>
              <span className="speaker-pattern"></span>
            </h2>
          </div>

          <div className="col-md-12 p-0 videos-wrpr" style={{ position: 'relative' }}>
            <div className="swiper-leftarrow">
              <div className="swiper-button-prev video-prev"></div>
            </div>

            <Swiper
              spaceBetween={100}
              speed={500}
              loop={true}
              slidesPerView={3}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                el: '.swiper-pagination.video-pagination',
                clickable: true,
              }}
              navigation={{
                nextEl: ".swiper-button-next.video-next",
                prevEl: ".swiper-button-prev.video-prev",
              }}
              modules={[Autoplay, Pagination, Navigation]}
              className="swiper-container video-slide"
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20
                },
                480: {
                  slidesPerView: 3,
                  spaceBetween: 30
                },
                640: {
                  slidesPerView: 3,
                  spaceBetween: 40
                }
              }}
            >
              <div className="swiper-wrapper">
                {videoData?.map((video, index) => (
                  <SwiperSlide 
                    key={index}
                    className="swiper-slide venobox"
                    data-vbtype="video"
                    data-autoplay="true"
                    href={video?.link}
                    data-title={video?.title}
                  >
                   
                      <img 
                        className="video-img" 
                        src={`https://img.youtube.com/vi/${getYoutubeId(video?.link)}/hqdefault.jpg`}
                        alt={video?.title}
                      />
                      <div className="video-title">{video?.title}</div>
                   
                  </SwiperSlide>
                ))}
              </div>

              <div className="swiper-pagination-wrpr">
                <div className="swiper-pagination video-pagination"></div>
              </div>
            </Swiper>

            <div className="swiper-rightarrow">
              <div className="swiper-button-next video-next"></div>
            </div>
          </div>
          <span class="col-md-12 text-center section-btn-wrpr">
            <button 
            className="nav-link pl-0 btn btn-sm btn-dark btn-yellow " 
            onClick={handleListing}>
              View More
              </button>
          </span>
        </div>
      </div>

      {/* Add custom styles */}
      <style jsx>{`
        .video-img {
          width: 100%;
          border-radius: 8px;
          transition: transform 0.3s ease;
        }

        .video-title {
          margin-top: 10px;
          font-size: 14px;
          line-height: 1.4;
          text-align: center;
        }

        .swiper-slide:hover .video-img {
          transform: scale(1.05);
        }

        /* Custom VenoBox styling */
        :global(.vbox-close) {
          color: #fff;
          background: transparent;
        }

        :global(.vbox-title) {
          font-size: 16px;
          padding: 10px 40px;
          background: rgba(0,0,0,0.8);
        }
      `}</style>
    </div>
  );
};

// Helper function to extract YouTube video ID
const getYoutubeId = (link) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = link.match(regExp);
  return (match && match[2].length === 11) ? match[2] : null;
};

export default Videos;