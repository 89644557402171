import React, {useEffect, useState} from 'react'
import Footer from './Footer';
import Navbar from './Navbar';
import InitModal from './initModal';
import { useScrollToTop } from '../hooks/ScrollTop';
import siteQueries from '../queries/siteQueries';
import { useNavigate, useLocation, useParams} from 'react-router-dom';
import '../css/developer2.css'
import '../css/bootstrap.min.css';
import '../css/font.css';

export default function NewsView() {
    const [isOpen, setIsOpen] = useState(false);
    const [news, setNews] = useState([]);
    const handleClose = () => setIsOpen(false);
    const handleOpen = () => setIsOpen(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(4);
    const [newsview, setNewsview] = useState(null);

    const navigate = useNavigate();
    const { id } = useParams();

    const newsData = siteQueries.useNewsMutation(
        async (response) => {
          setNews(response?.data?.items);
        },
    );

    useEffect(() => {
        newsData.mutateAsync({
            page: currentPage,
            size: pageSize,
        })
    }, [])

    const newsDataview = siteQueries.useNewsviewsiteMutation(
        async (response) => {
            setNewsview(response?.data);
        },
    );

    useEffect(() => {
        // Fetch news view data when component mounts or id changes
        newsDataview.mutateAsync({ id });
    }, [id]);

    // Dynamic meta tag update effect
    useEffect(() => {
        if (newsview) {
            // Update page title
            document.title = `${newsview.shortdescription} | Kerala Literature Festival 2025`;
            
            // Update meta description
            const metaDescription = `${newsview.shortdescription} | KLF 2025 News - Kerala Literature Festival`;
            const descriptionMeta = document.querySelector('meta[name="description"]');
            if (descriptionMeta) {
                descriptionMeta.setAttribute('content', metaDescription);
            }

            // Update meta keywords
            const metaKeywords = `${newsview.shortdescription}, kerala, literature, festival, 2025`;
            const keywordsMeta = document.querySelector('meta[name="keywords"]');
            if (keywordsMeta) {
                keywordsMeta.setAttribute('content', metaKeywords);
            }
        }

        // Reset meta tags on component unmount
        return () => {
            document.title = 'KLF 2025 | Kerala Lit Fest | Literature Festival | Literary Festival 2025';
            
            const descriptionMeta = document.querySelector('meta[name="description"]');
            const keywordsMeta = document.querySelector('meta[name="keywords"]');

            if (descriptionMeta) {
                descriptionMeta.setAttribute('content', 'KLF 2025 Registration - Kerala Literature Festival | Register for Kerala Lit Fest');
            }

            if (keywordsMeta) {
                keywordsMeta.setAttribute('content', 'KLF 2025 Registration - Kerala Literature Festival | Register for Kerala Lit Fest');
            }
        };
    }, [newsview]);

    const handleReadMore = (item) => {
        window.scrollTo(0, 0);
        navigate(`/newsview/${item.id}`);
    }
    
    const handleBack = () => {
        navigate('/news')
    };

    const decodeHtml = (html) => {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = html;
        return textArea.value;
    };

    useScrollToTop();

    // Check if newsview is loaded
    if (!newsview) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Navbar handleOpen={handleOpen}  />
            <div className="innerbanner container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <span className="logo-strips"></span> 
                            <h2>{newsview?.shortdescription}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container mt-4">
                    <div className="row">
                        {/* Main Content Area */}
                        <div className="col-md-8">
                            <div className="card mb-4">
                                <img
                                    src={`${process.env.REACT_APP_BASE_URL}/${newsview?.image}`}
                                    alt={newsview.shortdescription}
                                    className="card-img-top"
                                    style={{ height: '650px', objectFit: 'cover' }}
                                />
                                <div className="card-body h-f">
                                    <h2 className="card-title">{newsview?.shortdescription}</h2>
                                    <span className="card-text">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: decodeHtml(newsview?.maindescription)
                                            }}
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* SideCard */}
                        <div className="col-md-4">
                            {news?.filter(item => item.id !== newsview.id).map((item, index) => (
                                <div 
                                    className="card mb-3 cursor-pointer" 
                                    key={index}
                                    style={{cursor: 'pointer'}}
                                    onClick={() => handleReadMore(item)}
                                >
                                    <img
                                        src={`${process.env.REACT_APP_BASE_URL}/${item?.image}`}
                                        alt={item?.shortdescription}
                                        className="card-img-top"
                                        style={{ height: '300px', objectFit: 'cover' }}
                                    />
                                    <div className="card-body">
                                        <h5 className="card-title text-truncate">{item?.shortdescription}</h5>
                                        <p 
                                            className="card-text"
                                            style={{
                                                display: '-webkit-box',
                                                WebkitLineClamp: 5, 
                                                WebkitBoxOrient: 'vertical',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: decodeHtml(item?.maindescription)
                                                }}
                                            />
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            
            <Footer handleOpen={handleOpen} />
            <InitModal isOpen={isOpen} onClose={handleClose} />
        </div>
    )
}
