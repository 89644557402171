import React, { useState } from "react";
import { useForm } from "react-hook-form";
import userQueries from '../../queries/userQueries';
import { useNavigate, } from 'react-router-dom';
import Loader from '../Loader/Loader';

const CreateSlider = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [susmsg, setSusmsg] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { register, handleSubmit,getValues, formState: { errors }, setError, clearErrors } = useForm();

    const AddInfodata = userQueries.useSlidercreateMutation(
        (response) => {
            if (response?.message === "Images added successfully to the slider") {
                setSusmsg(true);
                alert(response.data[0].status === 'Unpublish' ? "Saved as draft" : "Published successfully");
                navigate('/sliderlisting')
            }
            else if(response?.message === "Slider Created")
            {
                setSusmsg(true);
                alert(response.data.status === 'Unpublish' ? "Saved as draft" : "Published successfully");
                navigate('/sliderlisting')
            }
            
            else{
                alert(response.message)
            }
        }
    );

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(files);
        if (files.length === 0) {
            setError("files", {
                type: "manual",
                message: "At least one file must be selected"
            });
        }else { 
            clearErrors("files");
        }
    };

    const handleFormSubmit = async (data, publishStatus) => {

        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        const MAX_FILE_SIZE = 1024 * 1024; // 1MB in bytes
        if(selectedFiles[0])
        {
            if (!allowedTypes.includes(selectedFiles[0].type)) {
                setError("files", {
                    type: "manual",
                    message: "Only JPEG, JPG and PNG files are allowed."
                });
                return;
            }
    
            // Check file size
            if (selectedFiles[0].size > MAX_FILE_SIZE) {
                
                setError("files", {
                    type: "manual",
                    message: "File size must be less than or equal to 1MB."
                });
                return;
            }
        }
       
        if(publishStatus === "Publish")
        {

            if (selectedFiles.length === 0) {
                setError("files", {
                    type: "manual",
                    message: "At least one file must be selected"
                });
                return;
            }
            
        }
        

        const formData = new FormData();
        formData.append('status', publishStatus);
        formData.append('displayorder', data.displayorder || 1); 

        selectedFiles.forEach((file) => {
            formData.append('slider', file);
        });


        try {
            const createdSlider = await AddInfodata.mutateAsync(formData);
        } catch (error) {
            console.error("Error creating slider:", error);
        }
    };

    return (
        <>
            <h4 className="mb-6 text-xl font-semibold text-black dark:text-white">
                Create Sliders
            </h4>
            <form className="max-w-lg mx-auto grid grid-cols-2 gap-4">
               
                {/* File input field */}
                <div className="mb-5 col-span-2">
                    <label htmlFor="files" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Upload Sliders
                    </label>
                    <input 
                        type="file" 
                        id="files" 
                        name="files" 
                        multiple 
                        accept="image/*" 
                        onChange={handleFileChange} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                    {errors.files && <p className="text-red-500 text-s mt-1">{errors.files.message}</p>}
                    <span class="form-text text-warning small">Recommended image size: less than 1000 KB.</span>
                </div>

                {/* Display selected files */}
                {selectedFiles.length > 0 && (
                    <div className="mb-5 col-span-2">
                        <p className="text-sm font-medium text-gray-900 dark:text-white">Selected Files:</p>
                        <ul className="list-disc pl-5">
                            {selectedFiles.map((file, index) => (
                                <li key={index} className="text-sm text-gray-600 dark:text-gray-300">{file.name}</li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className="mb-5">
                    <label htmlFor="displayorder" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Display Order</label>
                    <input 
                        type="number" 
                        id="displayorder" 
                        {...register("displayorder", { required: false })} 
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5" 
                    />
                
                </div>

                <button 
                    type="button" 
                    onClick={() => handleFormSubmit(getValues(), 'Unpublish')}
                    className="col-span-2 w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-3"
                >
                    Save as draft
                </button>

                <button 
                    type="button"
                    onClick={handleSubmit((data) => handleFormSubmit(data, 'Publish'))}
                    className="col-span-2 w-full text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save and publish
                </button>
                {/* Loader */}
                {loading && (
                    <Loader />
                )}

                {/* Success message */}
                {/* {susmsg && (
                    <div className="col-span-2 text-green-600 text-lg text-center">
                        Slider created successfully
                    </div>
                )} */}
            </form>
        </>
    );
};

export default CreateSlider;
