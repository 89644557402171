import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import siteQueries from '../queries/siteQueries';
import { Navigation, Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import RouteConstants from '../constant/Routeconstant';



const Slider = () => {
  const [slider, setSlider] = useState([]);
  const navigate = useNavigate();

  const sliderlist = siteQueries.useSliderMutation(
    async (response) => {
      setSlider(response?.data || []);
    },
    {
      onError: (error) => {
      }
    }
  );

  useEffect(() => {
    sliderlist.mutateAsync({});
  }, []);

  return (
    <div className="banner container-fluid">
      <div className="row">
        <div className="col-md-12 p-0 text-center">
          <div className="desktoponly">
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              loop={true}
              speed={500}
              slidesPerView={1}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              pagination={{
                el: '.swiper-pagination.banner-pagination',
                clickable: true,
              }}
              navigation={{
                nextEl: '.swiper-button-next.banner-next',
                prevEl: '.swiper-button-prev.banner-prev',
              }}
              className="swiper-container main-banner"
            >
              {slider && slider !== null && slider.map((slide, index) => (
                <SwiperSlide className="swiper-slide" key={index}>
                  <img src={`${process.env.REACT_APP_BASE_URL}/${slide?.photo}`} alt="sliderimg" />
                </SwiperSlide>
              ))}
              <div className="swiper-button-next banner-next"></div>
              <div className="swiper-button-prev banner-prev"></div>
            </Swiper>
          </div>

          <div className="phoneonly">
            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              loop={true}
              speed={500}
              slidesPerView={1}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              pagination={{
                el: '.swiper-pagination.banner-pagination',
                clickable: true,
              }}
              navigation={{
                nextEl: '.swiper-button-next.banner-next',
                prevEl: '.swiper-button-prev.banner-prev',
              }}
              className="swiper-container main-banner"
            >
              {slider && slider !== null && slider.map((slide, index) => (
                <SwiperSlide className="swiper-slide" key={index}>
                  <img src={`${process.env.REACT_APP_BASE_URL}/${slide?.photo}`} alt="sliderimg" />
                </SwiperSlide>
              ))}
              <div className="swiper-pagination gallery-pagination-mobile"></div>
              <div className="swiper-button-next gallery-next-mobile"></div>
              <div className="swiper-button-prev gallery-prev-mobile"></div>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;