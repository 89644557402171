import React, { useState,useEffect } from 'react';
import { Phone, Mail, Share2, ChevronDown } from 'lucide-react';
import { useLocation } from 'react-router-dom';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';

const Speakerview = () => {
  const location = useLocation();
  const stateData = location.state;
  const [showMore, setShowMore] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
    useEffect(() => {
        // Retrieve current page from navigation state
        const navigationState = location.state;
       
        if (navigationState?.currentPage !== undefined) {
          setCurrentPage(navigationState.currentPage);
          localStorage.setItem('speakerListCurrentPage', navigationState.currentPage.toString());
        }
      }, [location]);
      localStorage.setItem('speakerListCurrentPage', currentPage.toString());

  const decodeHtml = (html) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = html;
    return textArea.value;
  };

  return (
    <>
      <Breadcrumb pageName="Speaker View" />
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <div className="bg-white shadow-xl rounded-2xl overflow-hidden transform transition-all duration-300 hover:shadow-2xl">
            {/* Hero Image Section */}
            <div className="relative h-72 md:h-96 overflow-hidden">
              <div className="absolute inset-0 bg-gradient-to-b from-black/50 to-transparent z-10" />
              <img
                src={`${process.env.REACT_APP_BASE_URL}/${stateData.user.photo}`}
                alt="Speaker"
                className="w-full h-full object-cover transform transition-transform duration-700 hover:scale-105"
              />
              {/* Floating Share Button */}

            </div>

            <div className="p-6 md:p-8">
              {/* Heading Section */}
              <div className="mb-8">
                <h1 
                  className="text-4xl font-bold text-gray-900 mb-3 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent"
                >
                  {stateData.user.heading}
                </h1>
                <div className="h-1 w-24 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full" />
              </div>

              {/* Main Content Section */}
              <div 
                className={`prose max-w-none mb-8 text-gray-600 leading-relaxed ${
                  !showMore ? 'line-clamp-4' : ''
                }`}
                dangerouslySetInnerHTML={{
                  __html: decodeHtml(stateData.user.content)
                }}
              />
              
              {/* Show More Button */}
              <button
                onClick={() => setShowMore(!showMore)}
                className="flex items-center gap-2 text-blue-500 hover:text-blue-600 transition-colors duration-300 mb-8"
              >
                <span>{showMore ? 'Show Less' : 'Show More'}</span>
                <ChevronDown className={`h-4 w-4 transform transition-transform duration-300 ${showMore ? 'rotate-180' : ''}`} />
              </button>

              {/* Contact Information Card */}
              <div className="bg-gray-50 rounded-xl p-6 space-y-4">
                <h2 className="text-lg font-semibold text-gray-900 mb-4">Contact Information</h2>
                <div className="flex items-center space-x-3 text-gray-700 hover:bg-white p-3 rounded-lg transition-all duration-300 cursor-pointer group">
                  <div className="bg-blue-100 p-2 rounded-lg group-hover:bg-blue-500 transition-colors duration-300">
                    <Phone className="h-5 w-5 text-blue-500 group-hover:text-white" />
                  </div>
                  <span className="font-medium">{stateData?.user.phone}</span>
                </div>
                <div className="flex items-center space-x-3 text-gray-700 hover:bg-white p-3 rounded-lg transition-all duration-300 cursor-pointer group">
                  <div className="bg-purple-100 p-2 rounded-lg group-hover:bg-purple-500 transition-colors duration-300">
                    <Mail className="h-5 w-5 text-purple-500 group-hover:text-white" />
                  </div>
                  <span className="font-medium">{stateData?.user.email}</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Speakerview;