import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const accessToken = userStore.getState().user.jwttoken
const authService = {

    login: async (user) => {
        return apiRequest({
            method:"POST",
            url: "/user/login",
            data: user,
        });
    },

    userCreate: async (user) => {
        return apiRequest({
            method:"POST",
            url: "/user/create",
            data: user,
        });
    },

    paymentOrder: async (user) => {
        return apiRequest({
            method:"POST",
            url: "/delegatePayment/order",
            data: user,
            headers: {
                Authorization: "Bearer " + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzQsInVzZXJuYW1lIjoiYWRtaW4iLCJyb2xlcyI6WyJhZG1pbiJdLCJpYXQiOjE3MjgzNTAwNDd9.E-C79gZDzIOZhqXJbPPDeOyEDLomdtoJzuX5x5RyTUY',
            }
        });
    },

    paymentVerification: async (user) => {
        return apiRequest({
            method:"POST",
            url: "/delegatePayment/verify/payment",
            data: user,
            headers: {
                Authorization: "Bearer " + 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MzQsInVzZXJuYW1lIjoiYWRtaW4iLCJyb2xlcyI6WyJhZG1pbiJdLCJpYXQiOjE3MjgzNTAwNDd9.E-C79gZDzIOZhqXJbPPDeOyEDLomdtoJzuX5x5RyTUY',
            }
        });
    },

    zohoLead: async (user) => {
        return apiRequest({
            method:"POST",
            url: "/zoho/leads",
            data: user,
        });
    },

}


export default authService;
