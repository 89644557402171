import React, { useState } from 'react';
import klfLogo from '../images/klf-logo.png';
import { useNavigate } from 'react-router-dom';
export default function Navbar({ handleOpen }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false); // State to control navbar toggle

  const handleAbout = () => {
    navigate('/about');
    setIsOpen(false); // Close menu after navigation
  };

  const handleArchives = () => {
    // window.location.href = 'https://archives.keralaliteraturefestival.com'; 
    window.open('https://archives.keralaliteraturefestival.com');
    setIsOpen(false);
};

  const handleHome = () => {
    navigate('/');
    setIsOpen(false); 
  };


  const handleRegister = () => {
    navigate('/register');
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen); // Toggle menu open/close state
  };

  const handleSpeaker =() => {
    navigate('/speakers');
  }

  return (
    <nav id="navbar" className="navbar navbar-expand-lg justify-content-center sticky-top bg-white">
      <div className="container-fluid menu-wrpr">
        <a style={{ cursor: 'pointer' }} onClick={handleHome} className="navbar-brand d-block text-left order-0 order-md-1">
          <img src={klfLogo} width="264" height="" alt="Kerala Literature Festival" />
        </a>
        <a className="nav-link pl-0 reqdemo phoneonly phone-reg" data-bs-toggle="modal" data-bs-target="#registrationType" onClick={handleRegister} >Register</a>
        <button className="navbar-toggler" type="button" onClick={toggleNavbar}>
        <span class="navbar-toggler-icon">
						<span></span>
					</span>
        </button>
        {/* Animated dropdown */}
        <div className={`navbar-collapse collapse text-center dual-nav order-1 order-md-1`}>
          <div className="menu-and-search-wrpr ms-auto">
            <ul style={{ cursor: 'pointer' }} className="navbar-nav headernav menuAnim">
              <li className="nav-item">
                <a className="nav-link pl-0" onClick={handleAbout}>
                  About
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" onClick={handleSpeaker}>
                  Speakers
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" href='/news'>
                  News
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" href='/videos'>
                  Video
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" href='/photos'>
                  Photos
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0 reqdemo" onClick={handleRegister} data-bs-toggle="modal" data-bs-target="#myModal">
                  Register
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="phoneonly">
          <div className={`phone-nav navbar-collapse collapse ${isOpen ? 'show' : ''}`} id="mobnav">
            <ul className="navbar-nav headernav">
              
              <li className="nav-item">
              <a className="nav-link pl-0" onClick={handleAbout}>
                  About
                </a>
              </li>
              <li className="nav-item">
              <a className="nav-link pl-0" onClick={handleSpeaker}>
              Speakers
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" href='/news'>
                  News
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" href='/videos'>
                  Video
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pl-0" href='/photos'>
                  Photos
                </a>
              </li>
              <li className="nav-item">
                <a onClick={handleRegister} className="nav-link pl-0 reqdemo" data-bs-toggle="modal" data-bs-target="#myModal">
                  Register
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
