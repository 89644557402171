import React from 'react';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';

const MultiSelect = ({
  name,
  control,
  options,
  isMulti = false,
  placeholder = 'Select...',
  isClearable = true,
  isSearchable = true,
  isDisabled = false,
  label,
  error,
}) => {
  return (
    <div className="mb-4">
      {label && (
        <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <ReactSelect
            {...field}
            options={options}
            isMulti={isMulti}
            placeholder={placeholder}
            isClearable={isClearable}
            isSearchable={isSearchable}
            isDisabled={isDisabled}
            className="basic-multi-select"
            classNamePrefix="select"
          />
        )}
      />
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default MultiSelect;